import { Component, QueryList, ViewChildren } from '@angular/core';
import { NavController } from '@ionic/angular';
import { RestService } from './rest.service';
import { Location } from "@angular/common";
import { AlertController } from '@ionic/angular';
import { NotificationsService } from '../app/pages/notifications.service';
import { Network } from '@ionic-native/network/ngx';
import { Storage } from '@ionic/storage';

//import { Firebase } from '@ionic-native/firebase/ngx';
import {
  Platform,
  IonRouterOutlet,
  ModalController,
  ToastController
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { LoginService } from '../app/pages/login.service';
//import { FcmService } from '../app/pages/fcm.service';

import { FCM } from '@ionic-native/fcm/ngx';


//import { Subject } from 'rxjs/Subject';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  loginFlag:any;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  updatetoken = { Parlor_Id: "", Token_Id: "" }
  tokenrespone: any;
  requestprofiledata = {
    Parlor_Id: ''
  }
  profiledata: any;
  Plan_Id = "";
  isGSTUser:any;

  public pageList: any[] = []

  public pageListAdvance = [
    // {
    //   iconName: 'avatarr.png', displayText: 'Profile', url: '/profile', expanded: false, hasChild: false,
    // },
    {
      iconName: 'unnamed.png', displayText: 'Dashboard', url: 'tabs/home', expanded: false, hasChild: false,
    },
    {
      iconName: 'CreateClient.png', displayText: 'Client Management', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'CreateClient.png', displayText: 'Create Client', url: '/createclient' },
        { iconName: 'AppointmentEntry.png', displayText: 'Appointment Entry', url: '/advancebookappointment' },
        { iconName: 'BillEntry.png', displayText: 'Bill Entry', url: '/advancebiientry' },
        { iconName: 'EnquiryEntry.png', displayText: 'Feedback Entry', url: '/feedback' },
        // { iconName: 'EnquiryEntry.png', displayText: 'Test', url: '/testpage' },
        { iconName: 'EnquiryEntry.png', displayText: 'Enquiry Entry', url: '/enquiryform' }
      ]
    },
    {
      iconName: 'EnquiryList.png', displayText: 'Client List', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'EnquiryList.png', displayText: 'Client List', url: '/clientlist' },
        { iconName: 'EnquiryList.png', displayText: 'Follow Up List', url: '/followuplist' },
        { iconName: 'EnquiryList.png', displayText: 'Enquiry List', url: '/enquirylist' },
        { iconName: 'Report.png', displayText: 'Feedback List', url: '/feedbacklist' }
      ]
    },
    // {
    //   iconName: 'ServiceList.png', displayText: 'Services', expanded: false, hasChild: true,
    //   subOptions: [
    //     { iconName: 'ServiceList.png', displayText: 'Service List', url: '/servicelist' },
    //     { iconName: 'CreateService.png', displayText: ' Create Service', url: '/createservice' }
    //   ]
    // },
    {
      iconName: 'staff.png', displayText: 'Staff', expanded: false, hasChild: true,
      subOptions: [
        // { iconName: 'staff.png', displayText: 'Create Staff', url: '/createstaff' },
        { iconName: 'commissionentry.png', displayText: 'Commission Entry', url: '/commission-entry' },
        { iconName: 'staffadvance.png', displayText: 'Staff Advance', url: '/staff-advance' },
        { iconName: 'salaryentry.png', displayText: 'Salary Entry', url: '/salaryentry' },
        { iconName: 'advancereceived.png', displayText: 'Advance Receive', url: '/advancereceive' }

      ]
    },
    {
      iconName: 'stafflist.png', displayText: 'Staff List', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'stafflist.png', displayText: 'Staff List', url: '/staff-list' },
        { iconName: 'advancelist.png', displayText: 'Advance List', url: '/advancelist' },
        { iconName: 'staffsalarylist.png', displayText: 'Salary List', url: '/staffsalarylist' },
        { iconName: 'advancereceivedlist.png', displayText: 'Advance Receive List', url: '/advancerecivelist' },
        // { iconName: 'staffsalarylist.png', displayText: 'Staff Salary List', url: '/staffsalarylist' },
        { iconName: 'commissionentry.png', displayText: 'Commission List', url: '/commissionlist' },
      ]
    },
    {
      iconName: 'Staffattendance.png', displayText: 'Staff Attendance', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'Staffattendance.png', displayText: 'Staff Attendance', url: '/staffattendance' },
        { iconName: 'AppointmentRegular.png', displayText: 'Staff Attendance List', url: '/attendancelist' }
      ]
    },
    {
      iconName: 'expensesentry.png', displayText: 'Product/Expence', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'supplierlist.png', displayText: 'Supplier Entry', url: '/supplier-master' },
        { iconName: 'productpurchase.png', displayText: 'Product Purchase', url: '/productpurches' },
        { iconName: 'expensesentry.png', displayText: 'Expence Entry', url: '/expenses-entry' }
      ]
    },
    {
      iconName: 'expenselist.png', displayText: 'Product/Expence List', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'Productpurchaselist.png', displayText: 'Product Purchase List', url: '/productpurchaselist' },
        { iconName: 'expenselist.png', displayText: 'Expence List', url: '/expencelist' },
        { iconName: 'supplierlist.png', displayText: 'Supplier List', url: '/supplierlist' }
      ]
    },
    {
      iconName: 'Report.png', displayText: 'Reports', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'collectionReport.png', displayText: 'Collection Report', url: '/collectionreport' },
        { iconName: 'ServiceList.png', displayText: 'Client Report', url: '/clientreport' },
        { iconName: 'staffsalarylist.png', displayText: 'Staff Report', url: '/staffreport' },
        { iconName: 'DateTime.png', displayText: 'Parlour Report', url: '/parlourreport' },
        { iconName: 'staffsalarylist.png', displayText: 'Staff Commission Report', url: '/staff-commision-report' },
        { iconName: 'collectionReport.png', displayText: 'Client Reminder Report', url: '/reminder-report' }
      ]
    },

    {
      iconName: 'staff.png', displayText: 'User Management', expanded: false, hasChild: true,
      subOptions: [
       // { iconName: 'staff.png', displayText: 'Create Staff', url: '/createstaff' },
        { iconName: 'staff.png', displayText: 'Create Staff', url: '/basic-staff-create' },
        { iconName: 'stafflist.png', displayText: 'Staff List', url: '/staff-list' },
        { iconName: 'staff.png', displayText: 'Create user', url: '/create-user' },
        { iconName: 'stafflist.png', displayText: 'User List', url: '/user-list' }

      ]
    },
    // {
    //   iconName: 'ic_setting.png', displayText: 'Setting', url: '/setting', expanded: false, hasChild: false,
    // },
    // {
    //   iconName: 'AppointmentRegular.png', displayText: 'Follow Up', url: '/followup', expanded: false, hasChild: false,
    // },
    {
      iconName: 'ic_online_booking_schedule.png', displayText: 'Online Booking Notification', url: '/bookingnotification', expanded: false, hasChild: false,
    },
    {
      iconName: 'SmsCounter.png', displayText: 'Custom SMS', url: '/customesms', expanded: false, hasChild: false,

    },
    {
      iconName: 'CreateClient.png', displayText: 'Staff Schedule', url: '/staffbusylist', expanded: false, hasChild: false,
    },
    {
      iconName: 'offer.png', displayText: 'Offer List', url: '/offer-list', expanded: false, hasChild: false,
    },
    {
      iconName: 'offer.png', displayText: 'Offer Photo', url: '/offer-photo', expanded: false, hasChild: false,
      
    },
    {
      iconName: 'gallery.png', displayText: 'Before & After', url: '/afterbefore', expanded: false, hasChild: false,

    },
    {
      iconName: 'gallery.png', displayText: 'Gallery', url: '/gallary', expanded: false, hasChild: false,

    },

  ];

  public pageListBasic = [
    // {
    //   iconName: 'avatarr.png', displayText: 'Profile', url: '/profile', expanded: false, hasChild: false,
    // },
    {
      iconName: 'unnamed.png', displayText: 'Dashboard', url: 'tabs/home', expanded: false, hasChild: false,
    },
   
    {
      iconName: 'CreateClient.png', displayText: 'Client Management', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'CreateClient.png', displayText: 'Create Client', url: '/createclient' },
        { iconName: 'AppointmentEntry.png', displayText: 'Appointment Entry', url: 'tabs/advancebookappointment-gst' },
        { iconName: 'BillEntry.png', displayText: 'Bill Entry', url: 'tabs/billing-gst' },
        { iconName: 'EnquiryEntry.png', displayText: 'Feedback Entry', url: '/feedback' },
        { iconName: 'EnquiryEntry.png', displayText: 'Enquiry Entry', url: '/enquiryform' }
      
      ]
    },
    {
      iconName: 'EnquiryList.png', displayText: 'Client List', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'EnquiryList.png', displayText: 'Client List', url: '/clientlist' },
        { iconName: 'EnquiryList.png', displayText: 'Follow Up List', url: '/followuplist' },
        { iconName: 'EnquiryList.png', displayText: 'Enquiry List', url: '/enquirylist' },
        { iconName: 'Report.png', displayText: 'Feedback List', url: '/feedbacklist' }
      ]
    },
    {
      iconName: 'CreateClient.png', displayText: 'Staff Management', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'CreateClient.png', displayText: 'Staff', url: '/basic-staff' },
        // { iconName: 'AppointmentEntry.png', displayText: 'Staff Salary', url: '/basic-staff-salary' },
        { iconName: 'BillEntry.png', displayText: 'Staff Advances', url: '/basic-staff-advance-list' },
        { iconName: 'EnquiryEntry.png', displayText: 'Staff Advance Return', url: '/basic-staff-advance-return-list' },
        { iconName: 'EnquiryEntry.png', displayText: 'Staff Attendance', url: '/basic-staff-attendance' },
        { iconName: 'EnquiryEntry.png', displayText: 'Staff Salary Report', url: '/basic-staff-salary-list' }
      ]
    },
    // {
    //   iconName: 'ServiceList.png', displayText: 'Services', expanded: false, hasChild: true,
    //   subOptions: [
    //     { iconName: 'ServiceList.png', displayText: 'Service List', url: '/servicelist' },
    //     { iconName: 'CreateService.png', displayText: ' Create Service', url: '/createservice' }
    //   ]
    // },
    {
      iconName: 'expensesentry.png', displayText: 'Expence', expanded: false, hasChild: true,
      subOptions: [
        // { iconName: 'supplierlist.png', displayText: 'Supplier Entry', url: '/supplier-master' },
        // { iconName: 'productpurchase.png', displayText: 'Product Purchase', url: '/productpurches' },
        { iconName: 'expensesentry.png', displayText: 'Expence Entry', url: '/expenses-entry' }
      ]
    },
    {
      iconName: 'expenselist.png', displayText: 'Expence List', expanded: false, hasChild: true,
      subOptions: [
        // { iconName: 'Productpurchaselist.png', displayText: 'Product Purchase List', url: '/productpurchaselist' },
        // { iconName: 'supplierlist.png', displayText: 'Supplier List', url: '/supplierlist' },
        { iconName: 'expenselist.png', displayText: 'Expence List', url: '/expencelist' }
      
      ]
    },
    {
      iconName: 'Report.png', displayText: 'Reports', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'collectionReport.png', displayText: 'Billing Report', url: '/collectionreport' },
        { iconName: 'collectionReport.png', displayText: 'Collection Report', url: '/report-daily-collection' },
        { iconName: 'ServiceList.png', displayText: 'Client Report', url: '/clientreport' },
        { iconName: 'DateTime.png', displayText: 'Parlour Report', url: '/parlourreport' },
        { iconName: 'collectionReport.png', displayText: 'Client Reminder Report', url: '/reminder-report' },
        { iconName: 'collectionReport.png', displayText: 'Staff Commission Report', url: '/basic-staff-commission-report' }
      ]
    },
    {
      iconName: 'staff.png', displayText: 'User Management', expanded: false, hasChild: true,
      subOptions: [
        // { iconName: 'staff.png', displayText: 'Create Staff', url: '/createstaff' },
        // { iconName: 'stafflist.png', displayText: 'Staff List', url: '/staff-list' },
        // { iconName: 'staff.png', displayText: 'Create user', url: '/create-user' },
        { iconName: 'stafflist.png', displayText: 'User List', url: '/user-list' }

      ]
    },
    {
      iconName: 'staff.png', displayText: 'Product Managment', expanded: false, hasChild: true,
      subOptions: [
        //  { iconName: 'staff.png', displayText: 'Supplier Master', url: '/supplier-master' },
         { iconName: 'staff.png', displayText: 'Supplier List', url: '/supplier-list' },
         { iconName: 'stafflist.png', displayText: 'Product Inward Master', url: '/productpurches' },
        { iconName: 'stafflist.png', displayText: 'Product Used In Salon', url: '/parlor-product-use' },
         { iconName: 'staff.png', displayText: 'Product Sale Analysis', url: '/sale-analysis' },
         { iconName: 'staff.png', displayText: 'Product Stock Analysis', url: '/stock-analysis' },
          { iconName: 'staff.png', displayText: 'Supplier Payment Entry', url: '/supplier-payment-entry' },
        //  { iconName: 'staff.png', displayText: 'Supplier Payment List', url: '/supplier-payment-summary' },
        // 
        // { iconName: 'stafflist.png', displayText: 'Product List', url: '/product-list' },
        // { iconName: 'staff.png', displayText: 'Stock Analaysis', url: '/stock-analysis' },
        
      ]
    },
    {
      iconName: 'Report.png', displayText: 'GST Report', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'collectionReport.png', displayText: 'Monthly Purchase GST Report', url: '/report-gstpurchase' },
        { iconName: 'collectionReport.png', displayText: 'R1 Purchase Report', url: '/report-gstpurchase-quarterly' },
        { iconName: 'collectionReport.png', displayText: 'Monthly Sale GST Report', url: '/report-gstsale' },
        { iconName: 'collectionReport.png', displayText: 'R1 Sale Report', url: '/report-gstsale-quarterly' },
       
      ]
    },
    // {
    //   iconName: 'ic_setting.png', displayText: 'Setting', url: '/setting', expanded: false, hasChild: false,
    // },
    // {
    //   iconName: 'AppointmentRegular.png', displayText: 'Follow Up', url: '/followup', expanded: false, hasChild: false,
    // },
    {
      iconName: 'ic_online_booking_schedule.png', displayText: 'Online Booking Notification', url: '/bookingnotification', expanded: false, hasChild: false,
    },
    {
      iconName: 'offer.png', displayText: 'Offer List', url: '/offer-list', expanded: false, hasChild: false,

    },
    {
      iconName: 'offer.png', displayText: 'Offer Photo', url: '/offer-photo', expanded: false, hasChild: false,

    },
    {
      iconName: 'gallery.png', displayText: 'Before & After', url: '/afterbefore', expanded: false, hasChild: false,

    },
    {
      iconName: 'gallery.png', displayText: 'Gallery', url: '/gallary', expanded: false, hasChild: false,

    },
    {
      iconName: 'SmsCounter.png', displayText: 'Custom SMS', url: '/customesms', expanded: false, hasChild: false,

    },
    {
      iconName: 'SmsCounter.png', displayText: 'Sharing Histroy', url: '/group-sharing-history', expanded: false, hasChild: false,

    },

  ];

  public pageListBasicNonGst = [
    
    {
      iconName: 'unnamed.png', displayText: 'Dashboard', url: 'tabs/home', expanded: false, hasChild: false,
    },
   
    {
      iconName: 'CreateClient.png', displayText: 'Client Management', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'CreateClient.png', displayText: 'Create Client', url: '/createclient' },
        { iconName: 'AppointmentEntry.png', displayText: 'Appointment Entry', url: 'tabs/advancebookappointment-non-gst' },
        { iconName: 'BillEntry.png', displayText: 'Bill Entry', url: 'tabs/billing-non-gst' },
        { iconName: 'EnquiryEntry.png', displayText: 'Feedback Entry', url: '/feedback' },
        { iconName: 'EnquiryEntry.png', displayText: 'Enquiry Entry', url: '/enquiryform' }
      
      ]
    },
    {
      iconName: 'EnquiryList.png', displayText: 'Client List', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'EnquiryList.png', displayText: 'Client List', url: '/clientlist' },
        { iconName: 'EnquiryList.png', displayText: 'Follow Up List', url: '/followuplist' },
        { iconName: 'EnquiryList.png', displayText: 'Enquiry List', url: '/enquirylist' },
        { iconName: 'Report.png', displayText: 'Feedback List', url: '/feedbacklist' }
      ]
    },
    {
      iconName: 'CreateClient.png', displayText: 'Staff Management', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'CreateClient.png', displayText: 'Staff', url: '/basic-staff' },
        { iconName: 'BillEntry.png', displayText: 'Staff Advances', url: '/basic-staff-advance-list' },
        { iconName: 'EnquiryEntry.png', displayText: 'Staff Advance Return', url: '/basic-staff-advance-return-list' },
        { iconName: 'EnquiryEntry.png', displayText: 'Staff Attendance', url: '/basic-staff-attendance' },
        { iconName: 'EnquiryEntry.png', displayText: 'Staff Salary Report', url: '/basic-staff-salary-list' }
      ]
    },
    {
      iconName: 'expensesentry.png', displayText: 'Expence', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'expensesentry.png', displayText: 'Expence Entry', url: '/expenses-entry' }
      ]
    },
    {
      iconName: 'expenselist.png', displayText: 'Expence List', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'expenselist.png', displayText: 'Expence List', url: '/expencelist' }
      
      ]
    },
    {
      iconName: 'Report.png', displayText: 'Reports', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'collectionReport.png', displayText: 'Billing Report', url: '/collectionreport' },
        { iconName: 'collectionReport.png', displayText: 'Collection Report', url: '/report-daily-collection' },
        { iconName: 'ServiceList.png', displayText: 'Client Report', url: '/clientreport' },
        { iconName: 'DateTime.png', displayText: 'Parlour Report', url: '/parlourreport' },
        { iconName: 'collectionReport.png', displayText: 'Client Reminder Report', url: '/reminder-report' },
        { iconName: 'collectionReport.png', displayText: 'Staff Commission Report', url: '/basic-staff-commission-report' }
      ]
    },
    {
      iconName: 'staff.png', displayText: 'User Management', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'stafflist.png', displayText: 'User List', url: '/user-list' }

      ]
    },
    {
      iconName: 'staff.png', displayText: 'Product Managment', expanded: false, hasChild: true,
      subOptions: [
         { iconName: 'staff.png', displayText: 'Supplier List', url: '/supplier-list' },
         { iconName: 'stafflist.png', displayText: 'Product Inward Master', url: '/productpurches' },
        { iconName: 'stafflist.png', displayText: 'Product Used In Salon', url: '/parlor-product-use' },
         { iconName: 'staff.png', displayText: 'Product Sale Analysis', url: '/sale-analysis' },
         { iconName: 'staff.png', displayText: 'Product Stock Analysis', url: '/stock-analysis' },
          { iconName: 'staff.png', displayText: 'Supplier Payment Entry', url: '/supplier-payment-entry' },
        //  { iconName: 'staff.png', displayText: 'Supplier Payment List', url: '/supplier-payment-summary' },
        
      ]
    },
    {
      iconName: 'Report.png', displayText: 'GST Report', expanded: false, hasChild: true,
      subOptions: [
        { iconName: 'collectionReport.png', displayText: 'Monthly Purchase GST Report', url: '/report-gstpurchase' },
        { iconName: 'collectionReport.png', displayText: 'R1 Purchase Report', url: '/report-gstpurchase-quarterly' },
        { iconName: 'collectionReport.png', displayText: 'Monthly Sale GST Report', url: '/report-gstsale' },
        { iconName: 'collectionReport.png', displayText: 'R1 Sale Report', url: '/report-gstsale-quarterly' },
       
      ]
    },
    
    {
      iconName: 'ic_online_booking_schedule.png', displayText: 'Online Booking Notification', url: '/bookingnotification', expanded: false, hasChild: false,
    },
    {
      iconName: 'offer.png', displayText: 'Offer List', url: '/offer-list', expanded: false, hasChild: false,

    },
    {
      iconName: 'offer.png', displayText: 'Offer Photo', url: '/offer-photo', expanded: false, hasChild: false,

    },
    {
      iconName: 'gallery.png', displayText: 'Before & After', url: '/afterbefore', expanded: false, hasChild: false,

    },
    {
      iconName: 'gallery.png', displayText: 'Gallery', url: '/gallary', expanded: false, hasChild: false,

    },
    {
      iconName: 'SmsCounter.png', displayText: 'Custom SMS', url: '/customesms', expanded: false, hasChild: false,

    },
    {
      iconName: 'SmsCounter.png', displayText: 'Sharing Histroy', url: '/group-sharing-history', expanded: false, hasChild: false,

    },

  ];

  public freeMenu = [
    {iconName: 'unnamed.png', displayText: 'Dashboard', url: 'tabs/home', expanded: false, hasChild: false,},
    {iconName: 'CreateClient.png', displayText: 'Create Client', url: '/createclient', expanded: false, hasChild: false,},
    {iconName: 'AppointmentEntry.png', displayText: 'Appointment Entry', url: 'tabs/advancebookappointment-non-gst', expanded: false, hasChild: false,},
    {iconName: 'BillEntry.png', displayText: 'Bill Entry', url: 'tabs/billing', expanded: false, hasChild: false,},
    {iconName: 'EnquiryEntry.png', displayText: 'Feedback Entry', url: 'tabs/feedback' },
    {iconName: 'expensesentry.png', displayText: 'Expence Entry', url: '/expenses-entry' },
    {iconName: 'EnquiryList.png', displayText: 'Client List', url: '/clientlist', expanded: false, hasChild: false,},
    {iconName: 'Report.png', displayText: 'Feedback List', url: '/feedbacklist' },
    {iconName: 'expenselist.png', displayText: 'Expence List', url: '/expencelist' },
    {iconName: 'collectionReport.png', displayText: 'Billing Report', url: '/collectionreport' },
    {iconName: 'collectionReport.png', displayText: 'Collection Report', url: '/report-daily-collection', expanded: false, hasChild: false, },
    {iconName: 'collectionReport.png', displayText: 'Staff Commission Report', url: '/basic-staff-commission-report' },
    {iconName: 'staff.png', displayText: 'Create Staff', url: '/basic-staff' },
    {
      iconName: 'gallery.png', displayText: 'Gallery', url: '/gallary', expanded: false, hasChild: false,
    },
    {iconName: 'SmsCounter.png', displayText: 'Custom SMS', url: '/customesms', expanded: false, hasChild: false,}
  ];

  public staffMenu = [
    {
      iconName: 'unnamed.png', displayText: 'Dashboard', url: 'tabs/home', expanded: false, hasChild: false,
      
    },
    { iconName: 'unnamed.png', displayText: 'Commission Summary', url: '/staff-commission-summary' },
  ];

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public modalCtrl: ModalController,
    private router: Router,
    private toastController: ToastController,
    private navCtrl: NavController,
    private LoginService: LoginService,
    private fcm: FCM,
    private rest: RestService,
    public alertCtrl: AlertController,
    private storage: Storage,
    private location: Location,
    private network: Network,
    private noti: NotificationsService
  ) {
    // watch network for a disconnection
    let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
    });

    // stop disconnect watch
    disconnectSubscription.unsubscribe();


    // watch network for a connection
    let connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected!');
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      setTimeout(() => {
        if (this.network.type === 'wifi') {
          console.log('we got a wifi connection, woohoo!');
        }
      }, 3000);
    });

    // stop connect watch
    connectSubscription.unsubscribe();

    this.platform.ready()
      .then(() => {
        this.fcm.onNotification().subscribe(data => {
          if (data.wasTapped) {
            console.log("Received in background");
          } else {
            this.showNotification(data);
          };
        });

        this.fcm.onTokenRefresh().subscribe(token => {
          // Register your new token in your back-end if you want
          // backend.registerToken(token);
        });



      })


    // this.platform.backButton.subscribe(() =>{
    //   console.log("back button pressed");
    //  // this.location.isCurrentPathEqualTo()
    //  if(this.location.path()=='/tabs/home')
    //  {
    //   navigator['app'].exitApp();
    //  }
    //  else if(this.location.path()=='/appointmentdetail')
    //  {
    //   this.navCtrl.setDirection('root');
    //   this.navCtrl.navigateRoot('');
    //  }
    // else
    // {
    //   this.location.back();
    // }
    //   }) 
    this.initializeApp();


    this.getToken();
    this.LoginService.load();
    console.log("Login Flag : from Login service");
    console.log(this.LoginService.login.LoginFlag);
    this.noti.load();
   

    this.storage.get('loginFlag').then((val) => {
      if (val) {
        console.log("Login Flag : from storage app componet constr");
        console.log(val);
        this.loginFlag = val;
        console.log("Call getProfile : from storage app componet constr");
        this.getProfiledata();
      }
    })

    
  }
  ionViewDidLoad() {
    this.LoginService.load();
    this.requestprofiledata.Parlor_Id = this.LoginService.login.Parlor_Id;
    this.Plan_Id = this.LoginService.login.planId;
    this.storage.get('loginFlag').then((val) => {
      if (val) {
        console.log("Login Flag : from storage app componet ionViewDidLoad");
        console.log(val);
        this.loginFlag = val;
        console.log("Call getProfile : from storage app componet ionViewDidLoad");
        this.getProfiledata();
      }
    })

  
  }

  getProfiledata() {

    this.LoginService.load();
    this.requestprofiledata.Parlor_Id = this.LoginService.login.Parlor_Id;
    this.isGSTUser=this.LoginService.login.Is_GST_Applicable=="True"?true:false;
    console.log(this.LoginService.login.Parlor_Id);
    console.log("login flag"+this.loginFlag);
    this.rest.getProfileData(this.requestprofiledata).then(data => {

      console.log("hi from appcomponnet", data);
      this.storage.get('loginFlag').then((val) => {
        if (val) {
          console.log("Login Flag : from storage app componet constr");
          console.log(val);
          this.loginFlag = val;

          this.profiledata = data;
      this.Plan_Id = this.profiledata.getProfileDataResult.Plan_Id;
      if(this.loginFlag=="Parlour")
      {
        if (this.Plan_Id == "1") {
          //Basic Plan
          if(this.isGSTUser)
          {
            this.pageList = this.pageListBasic;
          }
          else
          {
            this.pageList = this.pageListBasicNonGst;
          }
          
          //this.pageList=this.freeMenu;
        }
        else if (this.Plan_Id == "2") {
          //Advance Plan
          this.pageList = this.pageListAdvance;
        }
        else if (this.Plan_Id == "7") {
          //Free Plan
          this.pageList = this.freeMenu;
        }
      }
      else if(this.loginFlag=="Staff")
      {
        this.pageList = this.staffMenu;
      }
        }
      })
      
    })


  }

  ionViewDidEnter() {
    this.requestprofiledata.Parlor_Id = this.LoginService.login.Parlor_Id;
    this.storage.get('loginFlag').then((val) => {
      if (val) {
        console.log("Login Flag : from storage app componet ionViewDidEnter");
        console.log(val);
        this.loginFlag = val;
        console.log("Call getProfile : from storage app componet ionViewDidEnter");
        this.getProfiledata();
      }
    })

    
  }
  async showNotification(data) {
    console.log(data);
    this.noti.addnotification(data);

    const alert = await this.alertCtrl.create({
      header: data.title,

      message: data.body,
      buttons: ['OK']

    });
    await alert.present();
  }

  subscribeToTopic() {
    this.fcm.subscribeToTopic('enappd');
  }
  getToken() {
    this.fcm.getToken().then(token => {
      // Register your new token in your back-end if you want
      // backend.registerToken(token);
      console.log("FC Token");
      console.log(token);
    });
  }
  unsubscribeFromTopic() {
    this.fcm.unsubscribeFromTopic('enappd');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.LoginService.load();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
      this.backButtonEvent();

      // this.requestapmtlist.Parlor_Id=this.loginervice.login.Parlor_Id;
    });


  }
  ionViewWillEnter() {
    this.storage.get('loginFlag').then((val) => {
      if (val) {
        console.log("Login Flag : from storage app componet ionViewWillEnter");
        console.log(val);
        this.loginFlag = val;

        console.log("Call getProfile : from storage app componet ionViewWillEnter");
        this.getProfiledata();
      }
    })
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      // try {
      //   const element = await this.modalCtrl.getTop();
      //   if (element) {
      //     element.dismiss();
      //     return;
      //   }
      // } catch (error) {
      //   console.log(error);
      // }

      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          outlet.pop();
        } else if (
          this.router.url === '/tabs/home' ||
          this.router.url === '/tabs/nearBy' ||
          this.router.url === '/tabs/profile' ||
          this.router.url === '/tabs/notification' ||
          this.router.url === '/tabs/appoinment' ||
          this.router.url === '/starter' ||
          this.router.url === '/sign-in'
        ) {
          if (
            new Date().getTime() - this.lastTimeBackPress <
            this.timePeriodToExit
          ) {
            navigator['app'].exitApp();
          } else {
            this.showToast();
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    });
  }
  async showToast() {
    const toast = await this.toastController.create({
      message: 'press back again to exit App.',
      duration: 2000
    });
    toast.present();
  }

  continue(page) {
    // this.navCtrl.navigateForward(page);
    // this.router.navigate([page]);
    debugger;
    this.navCtrl.navigateForward(page);

  }

  logout() {
    this.loginFlag="";
    this.storage.remove("loginFlag");
    this.LoginService.logout();
    this.rest.UpdateParlorToken(this.updatetoken).then(data => {
      console.log(data);
      this.tokenrespone = data;
      this.navCtrl.navigateRoot('/starter');
    });

  }

}
