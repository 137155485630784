import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ParlorService } from '../parlor.service';
import { RestService } from '../../rest.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../login.service';
import { Storage } from '@ionic/storage';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { LaodlingService } from '../laodling.service';
import { LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import * as moment from "moment";
@Component({
  selector: 'app-clientappointmentaction',
  templateUrl: './clientappointmentaction.page.html',
  styleUrls: ['./clientappointmentaction.page.scss'],
})
export class ClientappointmentactionPage implements OnInit {
  appointment: any;
  flag = 0;
  flag1 = 0;
  action: any;
  minDate: any;
  appointmentStatus: any;
  shareMessage: any;
  profileData: any;
  parlorInfo: any;
  address: any;
  bannerImageURL: any;
  planId: any;
  IsGst:any=false;
  loginServicePlanID: any;
  parlorId: any;
  bookingLink: any;
  appointmentMessage: any;
  appointmentCancelMessage: any;
  appointmentPostponedMessage: any;
  loginFlag:any;

  updaterequest = {
    Appointment_Entry_Id: "",
    Status: ""
  }

  onlineUpdateRequest = {
    Appointment_Entry_Id: "",
    Status: ""
  }

  onlinePostponedRequest = {
    Appointment_Entry_Id: "",
    Status: "",
    PostponeDate: "",
    PostponeTime: "",
    Appointment_Service_List: []
  }

  requestProfileData = {
    Parlor_Id: ''
  }

  getServiceCategoryDDLRequest = {
    Parlor_Id: "",
  }
  servicecategory:any;
  categoryDDL: any[] = [];
  appointmentServices: any[] = [];

  load: any;
  postpondrequest = {
    Appointment_Entry_Id: "",
    Status: "",
    PostponeDate: "",
    PostponeTime: "",
    Appointment_Service_List: []
  }
  response: any;
  doa: any;
  time: any;
  returnBasicRate: any;

  selectedItem=
  {
    date:"",
    time:""
  }
  actionReturn=
  {
    appointmentStatus:"",
    actionType:"",
    actionFlag:0,
    actionParameter:this.selectedItem
  }

  constructor(private loginService: LoginService, 
    public modalController: ModalController,
    public menuCtrl: MenuController, 
    public storage: Storage, 
    private fb: FormBuilder, 
    private rest: RestService,
    public loadingService: LaodlingService, 
    public datePipe: DatePipe, 
    private restService: RestService, 
    private navController: NavController, 
    private loader: LoadingController,
    private parlorService: ParlorService) {
    
        this.loginService.load();
        this.requestProfileData.Parlor_Id = this.loginService.login.Parlor_Id;
        this.getServiceCategoryDDLRequest.Parlor_Id = this.loginService.login.Parlor_Id;
        this.parlorInfo = this.loginService.login.parlorInfo;
        this.bannerImageURL = this.loginService.login.bannerImage_URL;
        this.parlorId = this.loginService.login.Parlor_Id;
        this.appointmentMessage = this.loginService.login.appointmentMsg;
        this.appointmentPostponedMessage = this.loginService.login.appointmentPostponeMeg;
        this.appointmentCancelMessage=this.loginService.login.appointmentCancelMeg;
        this.IsGst=this.loginService.login.Is_GST_Applicable="True"?true:false;
        console.log("Login Service :");
        console.log(this.loginService.login);
     }

     ionViewDidEnter() {
      this.minDate = new Date().toISOString();
      
      this.storage.get('loginFlag').then((val) => {
        if (val) {
          console.log("Login Flag : from storage clientAppointment");
          console.log(val);
          this.loginFlag = val;
        }
      })
      
      return this.storage.get('appointment').then((val) => {
        if (val) {
          this.flag = 1;
          console.log(val);
          this.appointment = val;
          this.appointmentStatus = this.appointment.Appointment_Status;
          console.log(this.appointmentStatus);
        }
      })
    }
  
    ngOnInit() {
    }
  
  
    async save() {
      this.actionReturn.appointmentStatus=this.appointmentStatus;
      this.actionReturn.actionType=this.action;
      this.actionReturn.actionFlag=this.flag1;
      if(this.flag1==1)
      {
        this.selectedItem.date=this.doa;
        this.selectedItem.time=this.time;
      }

      this.modalController.dismiss(this.actionReturn);
  
    }
  
  
    ionViewWillLeave() {
      if(this.load!=null)
      this.load.dismiss();
    }
  
  
 
  
  
    post() {
      if (this.action == "Postpone" || this.action == 'Reschedule & Approve') {
        this.flag1 = 1;
      }
      else
      {
        this.flag=0
      }
    }
  
  
   
  close() {
    this.modalController.dismiss();
  }

}
