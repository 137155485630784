import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../../pages/login.service';

@Component({
  selector: 'app-collectiondetail',
  templateUrl: './collectiondetail.page.html',
  styleUrls: ['./collectiondetail.page.scss'],
})
export class CollectiondetailPage implements OnInit {
  bill:any;
  clientname:any;
  mobileno:any;
  dob:any;
  discount:any;
  netamount:any;
  feedbackData:any;
  isShowFeedback:boolean;
  services:any[]=[];
  products:any[]=[];
  paymentModeCollection:any[]=[];
  total_amount:any;
  feedback_Content:any;                           
  rating:any;
  feedback_QA_Data:any;
  feedback_QA_Array:any[]=[];
  remark:any;
  ambience:any;
  staff:any;
  services_:any;
  PaymentMode="";

  serviceTotal:any=0;
  serviceDiscount:any=0;
  serviceTaxableAmount:any=0;
  serviceTaxAmount:any=0;
  serviceFinalTotal:any=0;

  productTotal:any=0;
  productDiscount:any=0;
  productTaxableAmount:any=0;
  productTaxAmount:any=0;
  productFinalTotal:any=0;

  planId:any;
  loginFlag:any;
  gstFlag:any;
  isGSTService:any;
  isGSTUser:any;
  showGSTPages:any=0;

  netTaxableAmount:any=0;
  netTaxAmount:any=0;
  addtinalDiscount:any=0;
  payableAmount:any=0;
  paidAmount:any=0;
  billNumber:any=0;
  oldDue:any=0;
  oldAdvance:any=0;
  usedAdvance:any=0;
  usedDue:any=0;
  currentDue:any=0;
  currentAdvance:any=0;

  

  constructor( private loginService: LoginService,public modalController: ModalController,public datepipe: DatePipe,public load :LaodlingService,private toast:ToastService, private storage:Storage, private parlor:ParlorService,private navCtrl: NavController,private rest:RestService) {
    this.loginService.load();
    this.isGSTService=this.loginService.login.IsServiceGST=="NA"?'0':'1';
    this.isGSTUser=this.loginService.login.Is_GST_Applicable=="True"?true:false;
    this.planId = this.loginService.login.planId;
    console.log("planId :: ");
    console.log(this.planId);
    if(this.planId==1)
    {
      if(this.isGSTUser)
      {
        this.showGSTPages=1;
      }
    }
    
    
    this.getdetail();
    
    }
  

  ngOnInit() {
  }
  getdetail(){
    this.load.present1();
    this.isShowFeedback=false;
    return this.storage.get('collection_detail').then((val) => {
      //debugger;
  this.bill=val;
  console.log("Bill details");
  console.log(this.bill);
  this.clientname=this.bill.Client_Data.Client_Name;
  this.mobileno=this.bill.Client_Data.Mobile_No;
  this.dob=this.bill.Bill_Date;
  this.billNumber=this.bill.Bill_No;

 this.oldDue= this.bill.Due_Amount
 this.oldAdvance= this.bill.Old_Advance
 this.usedAdvance= this.bill.Used_Advance
 this.usedDue= this.bill.Credit_Return
 this.currentDue= this.bill.Credit_Amount
 this.currentAdvance= this.bill.Advance_Amount
  //this.services=this.bill.Bill_Service_List;
  for (let serviceItem of this.bill.Bill_Service_List) 
  {
      let sDiscount=0;
      sDiscount=parseFloat(serviceItem.Amount)-parseFloat(serviceItem.Discounted_Rate)
      serviceItem.Total_Discounted_Amount=sDiscount;
      this.serviceTotal=this.serviceTotal+parseFloat(serviceItem.Amount);
      this.serviceDiscount=this.serviceDiscount+sDiscount;
      this.serviceTaxableAmount=this.serviceTaxableAmount+parseFloat(serviceItem.Basic_Rate);
      this.serviceTaxAmount=this.serviceTaxAmount+(parseFloat(serviceItem.CGST_Amount)+parseFloat(serviceItem.SGST_Amount));
      this.serviceFinalTotal=this.serviceFinalTotal+parseFloat(serviceItem.Final_Rate);

      this.services.push(serviceItem);
  }

  for (let productItem of this.bill.Bill_Product_List) 
  {
      let sDiscount=0;
      sDiscount=parseFloat(productItem.Amount)-parseFloat(productItem.Discounted_Rate)
      productItem.Total_Discounted_Amount=sDiscount;
      this.productTotal=this.productTotal+parseFloat(productItem.Amount);
      this.productDiscount=this.productDiscount+sDiscount;
      this.productTaxableAmount=this.productTaxableAmount+parseFloat(productItem.Basic_Rate);
      this.productTaxAmount=this.productTaxAmount+parseFloat(productItem.Total_GST_Amount);
      this.productFinalTotal=this.productFinalTotal+parseFloat(productItem.Final_Rate);

      this.products.push(productItem);
  }

  console.log("Product List :: ");
  console.log(this.products);
debugger;

  this.total_amount=this.bill.Total_Amount;
  this.discount=this.bill.Discount_Amount;
  this.netamount=this.bill.Net_Amount;
  this.netTaxableAmount=this.bill.Taxable_Amount;
  this.netTaxAmount=this.bill.Total_GST_Amount;
  this.addtinalDiscount=this.bill.Additional_Discount;
  this.payableAmount=this.bill.Payable_Amount
  this.paidAmount=this.bill.Paid_Amount
  this.PaymentMode=this.bill.Payment_Mode_Name;
  this.remark=this.bill.Remark;
  this.feedbackData=this.bill.Feedback_Data;
  if(this.feedbackData!=null)
  {
      this.isShowFeedback=true;
      this.feedback_Content=this.bill.Feedback_Data.Feedback_Content;                          
      this.rating=this.bill.Feedback_Data.Rating;
      this.feedback_QA_Array=this.bill.Feedback_Data.Feedback_QA_Data.split(',')
      console.log(this.feedback_QA_Array);
  }
  else
  {
    this.isShowFeedback=false;
  }

  if(this.bill.Client_Payment_Mode_List.length>0)
  {
    this.paymentModeCollection=[];
    this.paymentModeCollection=this.bill.Client_Payment_Mode_List;
  }
  
  console.log(this.bill);
  this.load.present1();
    })
  }


  close()
  {
    this.modalController.dismiss();
  }
}
