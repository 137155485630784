import { from } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ParlorService } from '../parlor.service';
import { RestService } from '../../rest.service';
import { DatePipe } from '@angular/common';
import { Storage } from '@ionic/storage';
import { LoginService } from '../login.service';
import { Moment } from 'moment'
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-supplier-add',
  templateUrl: './supplier-add.page.html',
  styleUrls: ['./supplier-add.page.scss'],
})
export class SupplierAddPage implements OnInit {


  supplierName: any = "";
  mobileNumber: any = "";
  Address: any = "";
  city: any = "";
  district: any = "";
  state: any = "";
  pinCode: any = "";
  gstNumber: any = "";
  Parlor_Id: any;
  emailId: any = "";

  supplierRequest = {
    objSupplier:
    {
      Parlor_Id: "",
      Supplier_Name: "",
      Mobile_No: "",
      Address: "",
      City: "",
      District: "",
      State: "",
      Pin_Code: "",
      Email_Id: "",
      GST_No: "",
    }
  }
  supplierResponse: any;

  user: any = {};
  categoryTypes: any;
  requestprofiledata = {
    Parlor_Id: ''
  }

  requestParlorType = {
    Parlor_Id: ''
  }
  Payment_Mode_Id = "0";


  constructor(public modalController: ModalController, private loginervice: LoginService, public menuCtrl: MenuController, public storage: Storage, private fb: FormBuilder, public load: LaodlingService, private toast: ToastService, public datepipe: DatePipe, private rest: RestService, private navCtrl: NavController, private parlor: ParlorService) {
  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.loginervice.load();
    this.Parlor_Id = this.loginervice.login.Parlor_Id;
  }

  addSupplier() {
    if (this.supplierName == "") {
      this.toast.show("Please enter supplier name");
      return;
    }
    if (this.mobileNumber == "" && this.mobileNumber.length != 10) {
      this.toast.show("Please enter valid mobile number");
      return;
    }

    if (this.city == "") {
      this.toast.show("Please enter supplier name");
      return;
    }
    if (this.district == "") {
      this.toast.show("Please enter supplier name");
      return;
    }
    if (this.pinCode == "") {
      this.toast.show("Please enter pin code");
      return;
    }


    this.supplierRequest.objSupplier.Parlor_Id = this.Parlor_Id;
    this.supplierRequest.objSupplier.Supplier_Name = this.supplierName;
    this.supplierRequest.objSupplier.Mobile_No = this.mobileNumber;
    this.supplierRequest.objSupplier.Address = this.Address;
    this.supplierRequest.objSupplier.City = this.city;
    this.supplierRequest.objSupplier.District = this.district;
    this.supplierRequest.objSupplier.State = this.state;
    this.supplierRequest.objSupplier.Pin_Code = this.pinCode;
    this.supplierRequest.objSupplier.Email_Id = this.emailId;
    this.supplierRequest.objSupplier.GST_No = this.gstNumber;

    this.rest.saveSupplier(this.supplierRequest).then(data => {
      this.load.present1();
      console.log(data);

      this.supplierResponse = data;
      if (this.supplierResponse.SaveSupplierResult.Result == "Success") {
        this.toast.show("supplier added Successfully");
        this.modalController.dismiss("success");

      } else {
        this.toast.show("Fail to add supplier");

      }

    });


  }

  close() {
    this.modalController.dismiss();
  }
}
