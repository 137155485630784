import { ProfilePageModule } from './pages/profile/profile.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },

  { path: '', redirectTo: 'menu/tabs/dashboard', pathMatch: 'full' },

  { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  {
    path: 'starter',
      loadChildren: './pages/starter/starter.module#StarterPageModule'
  },
  {
    path: 'sign-in',
    loadChildren: './pages/sign-in/sign-in.module#SignInPageModule'
  },
  {
    path: 'sign-up',
    loadChildren: './pages/sign-up/sign-up.module#SignUpPageModule'
  },
  {
    path: 'forgot-password',
    loadChildren:
      './pages/forgot-password/forgot-password.module#ForgotPasswordPageModule'
  },
  {
    path: 'slider',
    loadChildren: './pages/slider/slider.module#SliderPageModule'
  },
  {
    path: 'phone-verification',
    loadChildren:
      './pages/phone-verification/phone-verification.module#PhoneVerificationPageModule'
  },
  {
    path: 'otpverification',
    loadChildren:
      './pages/otpverification/otpverification.module#OTPVerificationPageModule'
  },
  {
    path: 'book-appointment',
    loadChildren:
      './pages/book-appointment/book-appointment.module#BookAppointmentPageModule'
  },
  {
    path: 'select-service',
    loadChildren:
      './pages/select-service/select-service.module#SelectServicePageModule'
  },
  {
    path: 'booking-detail',
    loadChildren:
      './pages/booking-detail/booking-detail.module#BookingDetailPageModule'
  },

  {
    path: 'salon-detail',
    loadChildren:
      './pages/salon-detail/salon-detail.module#SalonDetailPageModule'
  },
  { path: 'makepayment', loadChildren: './pages/makepayment/makepayment.module#MakepaymentPageModule' },
  { path: 'createclient', loadChildren: './pages/createclient/createclient.module#CreateclientPageModule' },
  { path: 'serviceentry', loadChildren: './pages/serviceentry/serviceentry.module#ServiceentryPageModule' },
  { path: 'customesms', loadChildren: './pages/customesms/customesms.module#CustomesmsPageModule' },
  { path: 'enquiryform', loadChildren: './pages/enquiryform/enquiryform.module#EnquiryformPageModule' },
  //{ path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule'},
  { path: 'followup', loadChildren: './pages/followuplist/followuplist.module#FollowuplistPageModule'},


  { path: 'appointment', loadChildren: './pages/appointment/appointment.module#AppointmentPageModule' },




  
  { path: 'upcomingevent', loadChildren: './pages/upcomingevent/upcomingevent.module#UpcomingeventPageModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'createservice', loadChildren: './pages/createservice/createservice.module#CreateservicePageModule' },
  { path: 'servicelist', loadChildren: './pages/servicelist/servicelist.module#ServicelistPageModule' },
  { path: 'editservice', loadChildren: './pages/editservice/editservice.module#EditservicePageModule' },
  { path: 'addservice', loadChildren: './pages/addservice/addservice.module#AddservicePageModule' },
  { path: 'appointmentdetail', loadChildren: './pages/appointmentdetail/appointmentdetail.module#AppointmentdetailPageModule' },
  { path: 'clientlist', loadChildren: './pages/clientlist/clientlist.module#ClientlistPageModule' },
  { path: 'subscription', loadChildren: './pages/subscription/subscription.module#SubscriptionPageModule' },
  { path: 'smstopup', loadChildren: './pages/smstopup/smstopup.module#SmstopupPageModule' },
  { path: 'collectionreport', loadChildren: './pages/collectionreport/collectionreport.module#CollectionreportPageModule' },
  { path: 'clienthistory', loadChildren: './pages/clienthistory/clienthistory.module#ClienthistoryPageModule' },
  { path: 'clienthistory1', loadChildren: './pages/clienthistory1/clienthistory1.module#Clienthistory1PageModule' },
  { path: 'feedback', loadChildren: './pages/feedback/feedback.module#FeedbackPageModule' },
  { path: 'enquirylist', loadChildren: './pages/enquirylist/enquirylist.module#EnquirylistPageModule' },
  { path: 'followuplist', loadChildren: './pages/followuplist/followuplist.module#FollowuplistPageModule' },
  { path: 'enquirydetail', loadChildren: './pages/enquirydetail/enquirydetail.module#EnquirydetailPageModule' },
  { path: 'collectiondetail', loadChildren: './pages/collectiondetail/collectiondetail.module#CollectiondetailPageModule' },
  { path: 'followupdetail', loadChildren: './pages/followupdetail/followupdetail.module#FollowupdetailPageModule' },
  { path: 'feedbacklist', loadChildren: './pages/feedbacklist/feedbacklist.module#FeedbacklistPageModule' },
  { path: 'sendoffers', loadChildren: './pages/sendoffers/sendoffers.module#SendoffersPageModule' },
  { path: 'otpverification1', loadChildren: './pages/otpverification1/otpverification1.module#Otpverification1PageModule' },
  { path: 'changepassword', loadChildren: './pages/changepassword/changepassword.module#ChangepasswordPageModule' },
  { path: 'parlorhistory', loadChildren: './pages/parlorhistory/parlorhistory.module#ParlorhistoryPageModule' },
  { path: 'updateprofile', loadChildren: './pages/updateprofile/updateprofile.module#UpdateprofilePageModule' },
  { path: 'editclient', loadChildren: './pages/editclient/editclient.module#EditclientPageModule' },
  { path: 'allnotifications', loadChildren: './pages/allnotifications/allnotifications.module#AllnotificationsPageModule' },
  { path: 'info', loadChildren: './pages/info/info.module#InfoPageModule' },
  { path: 'createstaff', loadChildren: './pages/createstaff/createstaff.module#CreatestaffPageModule' },
  { path: 'commission-entry', loadChildren: './pages/commission-entry/commission-entry.module#CommissionEntryPageModule' },
  { path: 'staff-advance', loadChildren: './pages/staff-advance/staff-advance.module#StaffAdvancePageModule' },
  { path: 'staff-list', loadChildren: './pages/staff-list/staff-list.module#StaffListPageModule' },
  { path: 'staff-details', loadChildren: './pages/staff-details/staff-details.module#StaffDetailsPageModule' },
  { path: 'staffdetails', loadChildren: './pages/staffdetails/staffdetails.module#StaffdetailsPageModule' },
  { path: 'advancelist', loadChildren: './pages/advancelist/advancelist.module#AdvancelistPageModule' },
  { path: 'editadvance', loadChildren: './pages/editadvance/editadvance.module#EditadvancePageModule' },
  { path: 'staffattendance', loadChildren: './pages/staffattendance/staffattendance.module#StaffattendancePageModule' },
  { path: 'salaryentry', loadChildren: './pages/salaryentry/salaryentry.module#SalaryentryPageModule' },
  { path: 'expenses-entry', loadChildren: './pages/expenses-entry/expenses-entry.module#ExpensesEntryPageModule' },
  { path: 'advancereceive', loadChildren: './pages/advancereceive/advancereceive.module#AdvancereceivePageModule' },
  { path: 'advancebookappointment', loadChildren: './pages/advancebookappointment/advancebookappointment.module#AdvancebookappointmentPageModule' },
  { path: 'productpurches', loadChildren: './pages/productpurches/productpurches.module#ProductpurchesPageModule' },
  { path: 'supplier-master', loadChildren: './pages/supplier-master/supplier-master.module#SupplierMasterPageModule' },
  { path: 'supplierlist', loadChildren: './pages/supplierlist/supplierlist.module#SupplierlistPageModule' },
  { path: 'supplierdetails', loadChildren: './pages/supplierdetails/supplierdetails.module#SupplierdetailsPageModule' },
  { path: 'expencelist', loadChildren: './pages/expencelist/expencelist.module#ExpencelistPageModule' },
  { path: 'advancerecive', loadChildren: './pages/advancerecive/advancerecive.module#AdvancerecivePageModule' },
  { path: 'advancerecivelist', loadChildren: './pages/advancerecivelist/advancerecivelist.module#AdvancerecivelistPageModule' },
  { path: 'productpurchaselist', loadChildren: './pages/productpurchaselist/productpurchaselist.module#ProductpurchaselistPageModule' },
  { path: 'productpurchasedetail', loadChildren: './pages/productpurchasedetail/productpurchasedetail.module#ProductpurchasedetailPageModule' },
  { path: 'staffsalarylist', loadChildren: './pages/staffsalarylist/staffsalarylist.module#StaffsalarylistPageModule' },
  { path: 'staffattendncelist', loadChildren: './pages/staffattendncelist/staffattendncelist.module#StaffattendncelistPageModule' },
  { path: 'attendancelist', loadChildren: './pages/attendancelist/attendancelist.module#AttendancelistPageModule' },
  { path: 'advancereciveedit', loadChildren: './pages/advancereciveedit/advancereciveedit.module#AdvancereciveeditPageModule' },
  { path: 'advancebiientry', loadChildren: './pages/advancebiientry/advancebiientry.module#AdvancebiientryPageModule' },
  { path: 'advanceappointmentdetail', loadChildren: './pages/advanceappointmentdetail/advanceappointmentdetail.module#AdvanceappointmentdetailPageModule' },
  { path: 'commissionlist', loadChildren: './pages/commissionlist/commissionlist.module#CommissionlistPageModule' },
  { path: 'staffbusylist', loadChildren: './pages/staffbusylist/staffbusylist.module#StaffbusylistPageModule' },
  { path: 'parlourreport', loadChildren: './pages/parlourreport/parlourreport.module#ParlourreportPageModule' },
  { path: 'staffreport', loadChildren: './pages/staffreport/staffreport.module#StaffreportPageModule' },
  { path: 'clientreport', loadChildren: './pages/clientreport/clientreport.module#ClientreportPageModule' },
  { path: 'parlou-timing', loadChildren: './pages/parlou-timing/parlou-timing.module#ParlouTimingPageModule' },
  { path: 'parlour-holiday', loadChildren: './pages/parlour-holiday/parlour-holiday.module#ParlourHolidayPageModule' },
  { path: 'staff-commision-report', loadChildren: './pages/staff-commision-report/staff-commision-report.module#StaffCommisionReportPageModule' },
  { path: 'gallary', loadChildren: './pages/gallary/gallary.module#GallaryPageModule' },
  { path: 'create-offer', loadChildren: './pages/create-offer/create-offer.module#CreateOfferPageModule' },
  { path: 'offer-list', loadChildren: './pages/offer-list/offer-list.module#OfferListPageModule' },
  { path: 'upgrade-plan', loadChildren: './pages/upgrade-plan/upgrade-plan.module#UpgradePlanPageModule' },
  { path: 'setting', loadChildren: './pages/setting/setting.module#SettingPageModule' },
  { path: 'banner-images', loadChildren: './pages/banner-images/banner-images.module#BannerImagesPageModule' },
  { path: 'bookingnotification', loadChildren: './pages/bookingnotification/bookingnotification.module#BookingnotificationPageModule' },
  { path: 'bookingschedule', loadChildren: './pages/bookingschedule/bookingschedule.module#BookingschedulePageModule' },
  { path: 'service-modal', loadChildren: './pages/service-modal/service-modal.module#ServiceModalPageModule' },
  { path: 'afterbefore', loadChildren: './pages/afterbefore/afterbefore.module#AfterbeforePageModule' },
  { path: 'clientcategory', loadChildren: './pages/clientcategory/clientcategory.module#ClientcategoryPageModule' },
  { path: 'clientzone', loadChildren: './pages/clientzone/clientzone.module#ClientzonePageModule' },
  { path: 'clientappointment', loadChildren: './pages/clientappointment/clientappointment.module#ClientappointmentPageModule' },
  { path: 'onlineappointment', loadChildren: './pages/onlineappointment/onlineappointment.module#OnlineappointmentPageModule' },
  { path: 'todaysdata', loadChildren: './pages/todaysdata/todaysdata.module#TodaysdataPageModule' },
  { path: 'testpage', loadChildren: './pages/testpage/testpage.module#TestpagePageModule' },
  { path: 'about-parlour', loadChildren: './pages/about-parlour/about-parlour.module#AboutParlourPageModule' },
  { path: 'reminder-report', loadChildren: './pages/reminder-report/reminder-report.module#ReminderReportPageModule' },
  { path: 'staff-login', loadChildren: './pages/staff-login/staff-login.module#StaffLoginPageModule' },
  { path: 'user-home', loadChildren: './pages/user-home/user-home.module#UserHomePageModule' },
  { path: 'create-user', loadChildren: './pages/create-user/create-user.module#CreateUserPageModule' },
  { path: 'user-list', loadChildren: './pages/user-list/user-list.module#UserListPageModule' },
  { path: 'user-add-client', loadChildren: './pages/user-add-client/user-add-client.module#UserAddClientPageModule' },
  { path: 'update-user', loadChildren: './pages/update-user/update-user.module#UpdateUserPageModule' },
  { path: 'customer-modal', loadChildren: './pages/customer-modal/customer-modal.module#CustomerModalPageModule' },
  { path: 'more-details', loadChildren: './pages/more-details/more-details.module#MoreDetailsPageModule' },
  { path: 'offer-photo', loadChildren: './pages/offer-photo/offer-photo.module#OfferPhotoPageModule' },
  { path: 'add-offer-photo', loadChildren: './pages/add-offer-photo/add-offer-photo.module#AddOfferPhotoPageModule' },
  { path: 'scroller-test', loadChildren: './pages/scroller-test/scroller-test.module#ScrollerTestPageModule' },
  { path: 'today-collection-data', loadChildren: './pages/today-collection-data/today-collection-data.module#TodayCollectionDataPageModule' },
  { path: 'analysis-data', loadChildren: './pages/analysis-data/analysis-data.module#AnalysisDataPageModule' },
  { path: 'analysis-service-category', loadChildren: './pages/analysis-service-category/analysis-service-category.module#AnalysisServiceCategoryPageModule' },
  { path: 'analysis-service-sub-category', loadChildren: './pages/analysis-service-sub-category/analysis-service-sub-category.module#AnalysisServiceSubCategoryPageModule' },
  { path: 'analysis-appointment', loadChildren: './pages/analysis-appointment/analysis-appointment.module#AnalysisAppointmentPageModule' },
  { path: 'analysis-feedback', loadChildren: './pages/analysis-feedback/analysis-feedback.module#AnalysisFeedbackPageModule' },
  { path: 'analysis-client-data', loadChildren: './pages/analysis-client-data/analysis-client-data.module#AnalysisClientDataPageModule' },
  { path: 'client-grouping', loadChildren: './pages/client-grouping/client-grouping.module#ClientGroupingPageModule' },
  { path: 'analysis-client-services', loadChildren: './pages/analysis-client-services/analysis-client-services.module#AnalysisClientServicesPageModule' },
  { path: 'analysis-client-appointments', loadChildren: './pages/analysis-client-appointments/analysis-client-appointments.module#AnalysisClientAppointmentsPageModule' },
  { path: 'analysis-appointment-details', loadChildren: './pages/analysis-appointment-details/analysis-appointment-details.module#AnalysisAppointmentDetailsPageModule' },
  { path: 'analysis-services-details', loadChildren: './pages/analysis-services-details/analysis-services-details.module#AnalysisServicesDetailsPageModule' },
  { path: 'client-group-transfer', loadChildren: './pages/client-group-transfer/client-group-transfer.module#ClientGroupTransferPageModule' },
  { path: 'analysis-client-feedbacks', loadChildren: './pages/analysis-client-feedbacks/analysis-client-feedbacks.module#AnalysisClientFeedbacksPageModule' },
  { path: 'staff-scheduler', loadChildren: './pages/staff-scheduler/staff-scheduler.module#StaffSchedulerPageModule' },
  { path: 'staff-basic-create', loadChildren: './pages/staff-basic-create/staff-basic-create.module#StaffBasicCreatePageModule' },
  { path: 'staff-basic-salary-structure', loadChildren: './pages/staff-basic-salary-structure/staff-basic-salary-structure.module#StaffBasicSalaryStructurePageModule' },
  { path: 'basic-staff-salary-structure', loadChildren: './pages/basic-staff-salary-structure/basic-staff-salary-structure.module#BasicStaffSalaryStructurePageModule' },
  { path: 'basic-staff-create', loadChildren: './pages/basic-staff-create/basic-staff-create.module#BasicStaffCreatePageModule' },
  { path: 'basic-staff-attendance', loadChildren: './pages/basic-staff-attendance/basic-staff-attendance.module#BasicStaffAttendancePageModule' },
  { path: 'basic-staff-adavance', loadChildren: './pages/basic-staff-adavance/basic-staff-adavance.module#BasicStaffAdavancePageModule' },
  { path: 'basic-staff-adavance-return', loadChildren: './pages/basic-staff-adavance-return/basic-staff-adavance-return.module#BasicStaffAdavanceReturnPageModule' },
  { path: 'basic-staff-salary', loadChildren: './pages/basic-staff-salary/basic-staff-salary.module#BasicStaffSalaryPageModule' },
  { path: 'basic-staff-advance-list', loadChildren: './pages/basic-staff-advance-list/basic-staff-advance-list.module#BasicStaffAdvanceListPageModule' },
  { path: 'basic-staff-salary-list', loadChildren: './pages/basic-staff-salary-list/basic-staff-salary-list.module#BasicStaffSalaryListPageModule' },
  { path: 'customer-advance', loadChildren: './pages/customer-advance/customer-advance.module#CustomerAdvancePageModule' },
  { path: 'customer-credit-debit', loadChildren: './pages/customer-credit-debit/customer-credit-debit.module#CustomerCreditDebitPageModule' },
  { path: 'basic-staff-attendance-update', loadChildren: './pages/basic-staff-attendance-update/basic-staff-attendance-update.module#BasicStaffAttendanceUpdatePageModule' },
  { path: 'staffattendancedetail', loadChildren: './pages/staffattendancedetail/staffattendancedetail.module#StaffattendancedetailPageModule' },
  { path: 'staffcommissiondetail', loadChildren: './pages/staffcommissiondetail/staffcommissiondetail.module#StaffcommissiondetailPageModule' },
  { path: 'staffadvancedetail', loadChildren: './pages/staffadvancedetail/staffadvancedetail.module#StaffadvancedetailPageModule' },
  { path: 'user-attendance', loadChildren: './pages/user-attendance/user-attendance.module#UserAttendancePageModule' },
  { path: 'basic-staff-salary-detail-update', loadChildren: './pages/basic-staff-salary-detail-update/basic-staff-salary-detail-update.module#BasicStaffSalaryDetailUpdatePageModule' },
  { path: 'staff-commission-update', loadChildren: './pages/staff-commission-update/staff-commission-update.module#StaffCommissionUpdatePageModule' },
  { path: 'staff-attendance-update', loadChildren: './pages/staff-attendance-update/staff-attendance-update.module#StaffAttendanceUpdatePageModule' },
  { path: 'basic-staff', loadChildren: './pages/basic-staff/basic-staff.module#BasicStaffPageModule' },
  { path: 'basic-staff-advance-return-list', loadChildren: './pages/basic-staff-advance-return-list/basic-staff-advance-return-list.module#BasicStaffAdvanceReturnListPageModule' },
  { path: 'analysis-service-client', loadChildren: './pages/analysis-service-client/analysis-service-client.module#AnalysisServiceClientPageModule' },
  { path: 'customer-advance-list', loadChildren: './pages/customer-advance-list/customer-advance-list.module#CustomerAdvanceListPageModule' },
  { path: 'customer-credit', loadChildren: './pages/customer-credit/customer-credit.module#CustomerCreditPageModule' },
  { path: 'collection-by-payment-mode', loadChildren: './pages/collection-by-payment-mode/collection-by-payment-mode.module#CollectionByPaymentModePageModule' },
  { path: 'collection-by-payment-type', loadChildren: './pages/collection-by-payment-type/collection-by-payment-type.module#CollectionByPaymentTypePageModule' },
  { path: 'collection-by-credit', loadChildren: './pages/collection-by-credit/collection-by-credit.module#CollectionByCreditPageModule' },
  { path: 'todays-collection-detail', loadChildren: './pages/todays-collection-detail/todays-collection-detail.module#TodaysCollectionDetailPageModule' },
  { path: 'collection-by-advance', loadChildren: './pages/collection-by-advance/collection-by-advance.module#CollectionByAdvancePageModule' },
  { path: 'collection-today', loadChildren: './pages/collection-today/collection-today.module#CollectionTodayPageModule' },
  { path: 'report-daily-collection', loadChildren: './pages/report-daily-collection/report-daily-collection.module#ReportDailyCollectionPageModule' },
  { path: 'group-sharing-history', loadChildren: './pages/group-sharing-history/group-sharing-history.module#GroupSharingHistoryPageModule' },
  { path: 'basic-staff-commission', loadChildren: './pages/basic-staff-commission/basic-staff-commission.module#BasicStaffCommissionPageModule' },
  { path: 'customer-advance-details', loadChildren: './pages/customer-advance-details/customer-advance-details.module#CustomerAdvanceDetailsPageModule' },
  { path: 'customer-credit-details', loadChildren: './pages/customer-credit-details/customer-credit-details.module#CustomerCreditDetailsPageModule' },
  { path: 'customer-bill-remarks', loadChildren: './pages/customer-bill-remarks/customer-bill-remarks.module#CustomerBillRemarksPageModule' },
  { path: 'basic-staff-commission-report', loadChildren: './pages/basic-staff-commission-report/basic-staff-commission-report.module#BasicStaffCommissionReportPageModule' },
  { path: 'service-staff-assign-popup', loadChildren: './pages/service-staff-assign-popup/service-staff-assign-popup.module#ServiceStaffAssignPopupPageModule' },
  { path: 'gstsetting', loadChildren: './pages/gstsetting/gstsetting.module#GstsettingPageModule' },
  { path: 'product-list', loadChildren: './pages/product-list/product-list.module#ProductListPageModule' },
  { path: 'product-add', loadChildren: './pages/product-add/product-add.module#ProductAddPageModule' },
  { path: 'product-edit', loadChildren: './pages/product-edit/product-edit.module#ProductEditPageModule' },
  { path: 'supplier-add', loadChildren: './pages/supplier-add/supplier-add.module#SupplierAddPageModule' },
  { path: 'supplier-edit', loadChildren: './pages/supplier-edit/supplier-edit.module#SupplierEditPageModule' },
  { path: 'supplier-payment-history', loadChildren: './pages/supplier-payment-history/supplier-payment-history.module#SupplierPaymentHistoryPageModule' },
  { path: 'supplier-payment-details', loadChildren: './pages/supplier-payment-details/supplier-payment-details.module#SupplierPaymentDetailsPageModule' },
  { path: 'supplier-list', loadChildren: './pages/supplier-list/supplier-list.module#SupplierListPageModule' },
  { path: 'supplier-billing-list', loadChildren: './pages/supplier-billing-list/supplier-billing-list.module#SupplierBillingListPageModule' },
  { path: 'supplier-bill-details', loadChildren: './pages/supplier-bill-details/supplier-bill-details.module#SupplierBillDetailsPageModule' },
  { path: 'stock-analysis', loadChildren: './pages/stock-analysis/stock-analysis.module#StockAnalysisPageModule' },
  { path: 'stock-company-analysis', loadChildren: './pages/stock-company-analysis/stock-company-analysis.module#StockCompanyAnalysisPageModule' },
  { path: 'stock-company-product-analysis', loadChildren: './pages/stock-company-product-analysis/stock-company-product-analysis.module#StockCompanyProductAnalysisPageModule' },
  { path: 'sale-analysis', loadChildren: './pages/sale-analysis/sale-analysis.module#SaleAnalysisPageModule' },
  { path: 'sale-company-analysis', loadChildren: './pages/sale-company-analysis/sale-company-analysis.module#SaleCompanyAnalysisPageModule' },
  { path: 'sale-product-analysis', loadChildren: './pages/sale-product-analysis/sale-product-analysis.module#SaleProductAnalysisPageModule' },
  { path: 'customer-product-analysis', loadChildren: './pages/customer-product-analysis/customer-product-analysis.module#CustomerProductAnalysisPageModule' },
  { path: 'report-gstpurchase', loadChildren: './pages/report-gstpurchase/report-gstpurchase.module#ReportGSTPurchasePageModule' },
  { path: 'report-gstsale', loadChildren: './pages/report-gstsale/report-gstsale.module#ReportGSTSalePageModule' },
  { path: 'report-gstpurchase-quarterly', loadChildren: './pages/report-gstpurchase-quarterly/report-gstpurchase-quarterly.module#ReportGSTPurchaseQuarterlyPageModule' },
  { path: 'report-gstsale-quarterly', loadChildren: './pages/report-gstsale-quarterly/report-gstsale-quarterly.module#ReportGSTSaleQuarterlyPageModule' },
  { path: 'gst-service-update', loadChildren: './pages/gst-service-update/gst-service-update.module#GstServiceUpdatePageModule' },
  { path: 'gst-product-update', loadChildren: './pages/gst-product-update/gst-product-update.module#GstProductUpdatePageModule' },
  { path: 'discountsetting', loadChildren: './pages/discountsetting/discountsetting.module#DiscountsettingPageModule' },
  { path: 'discount-update-service', loadChildren: './pages/discount-update-service/discount-update-service.module#DiscountUpdateServicePageModule' },
  { path: 'discount-update-product', loadChildren: './pages/discount-update-product/discount-update-product.module#DiscountUpdateProductPageModule' },
  { path: 'billinggstproduct', loadChildren: './pages/billinggstproduct/billinggstproduct.module#BillinggstproductPageModule' },
  //{ path: 'billingproduct', loadChildren: './pages/billingproduct/billingproduct.module#BillingproductPageModule' },
  { path: 'supplier-payment-summary', loadChildren: './pages/supplier-payment-summary/supplier-payment-summary.module#SupplierPaymentSummaryPageModule' },
  { path: 'parlor-product-use', loadChildren: './pages/parlor-product-use/parlor-product-use.module#ParlorProductUsePageModule' },
 
  { path: 'supplier-payment-entry', loadChildren: './pages/supplier-payment-entry/supplier-payment-entry.module#SupplierPaymentEntryPageModule' },
  { path: 'supplier-payment-entry', loadChildren: './supplier-payment-entry/supplier-payment-entry.module#SupplierPaymentEntryPageModule' },
  { path: 'appointmentdetail-gst', loadChildren: './pages/appointmentdetail-gst/appointmentdetail-gst.module#AppointmentdetailGSTPageModule' },
  { path: 'appointmentdetail-non-gst', loadChildren: './pages/appointmentdetail-non-gst/appointmentdetail-non-gst.module#AppointmentdetailNonGSTPageModule' },
  { path: 'parlor-product-use-list', loadChildren: './pages/parlor-product-use-list/parlor-product-use-list.module#ParlorProductUseListPageModule' },
  { path: 'staff-commission-summary', loadChildren: './pages/staff-commission-summary/staff-commission-summary.module#StaffCommissionSummaryPageModule' },
  { path: 'user-appointmentdetail-gst', loadChildren: './pages/user-appointmentdetail-gst/user-appointmentdetail-gst.module#UserAppointmentdetailGSTPageModule' },
  { path: 'user-appointmentdetail-non-gst', loadChildren: './pages/user-appointmentdetail-non-gst/user-appointmentdetail-non-gst.module#UserAppointmentdetailNonGSTPageModule' },
  { path: 'clientappointmentaction', loadChildren: './pages/clientappointmentaction/clientappointmentaction.module#ClientappointmentactionPageModule' },
  { path: 'create-client-new', loadChildren: './pages/create-client-new/create-client-new.module#CreateClientNewPageModule' },
  //{ path: 'user-billing-non-gst', loadChildren: './pages/user-billing-non-gst/user-billing-non-gst.module#UserBillingNonGstPageModule' },
  //{ path: 'user-billing-gst', loadChildren: './pages/user-billing-gst/user-billing-gst.module#UserBillingGstPageModule' },
  //{ path: 'user-appointment-gst', loadChildren: './pages/user-appointment-gst/user-appointment-gst.module#UserAppointmentGSTPageModule' },
  //{ path: 'user-appointment-non-gst', loadChildren: './pages/user-appointment-non-gst/user-appointment-non-gst.module#UserAppointmentNonGSTPageModule' },
  //{ path: 'billing', loadChildren: './pages/billing/billing.module#BillingPageModule' },
  //{ path: 'billing-non-gst', loadChildren: './pages/billing-non-gst/billing-non-gst.module#BillingNonGstPageModule' },
  //{ path: 'advancebookappointment-non-gst', loadChildren: './pages/advancebookappointment-non-gst/advancebookappointment-non-gst.module#AdvancebookappointmentNonGSTPageModule' },
  //{ path: 'advancebookappointment-gst', loadChildren: './pages/advancebookappointment-gst/advancebookappointment-gst.module#AdvancebookappointmentGSTPageModule' },
  
  // { path: 'user-add-appointment', loadChildren: './pages/user-add-appointment/user-add-appointment.module#UserAddAppointmentPageModule' },
  // { path: 'user-bill-entry', loadChildren: './pages/user-bill-entry/user-bill-entry.module#UserBillEntryPageModule' },
  // { path: 'user-feedback-entry', loadChildren: './pages/user-feedback-entry/user-feedback-entry.module#UserFeedbackEntryPageModule' },
  // { path: 'user-enquiry-entry', loadChildren: './pages/user-enquiry-entry/user-enquiry-entry.module#UserEnquiryEntryPageModule' },
  // { path: 'user-advance-appointment', loadChildren: './pages/user-advance-appointment/user-advance-appointment.module#UserAdvanceAppointmentPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {



  
}
