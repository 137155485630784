import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'url';
//import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class RestService 
{
 //url="https://testservice.preshitcreations.com/Service1.svc/"; // Production
 url="https://beautytestenvservice.parlourbooking.com/Service1.svc/"; //For dev test envirement
  //url="https://devparlorwebservice.preshitcreations.com/Service1.svc/";//dev test
 // url1="http://orangikatest.marketinsightsresearch.com/Service1.svc/";


 //url="https://preshitwebservices.preshitcreations.com/Service1.svc/";


  whatsappulr ="http://api.whatsapp.com/send?phone=9096145843&text=abesale";
  constructor(public http: HttpClient) { }

  login(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'CheckLogin', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   
   CheckStaffLogin(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'CheckStaffLogin', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getPlanList(data)
   {

    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getPlanList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

   }




   
   getStaffById(data)
   {

    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffById', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

   }

   addStaff(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaff', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

   }


   whatsaap()
   {

    return new Promise((resolve, reject) => {
      this.http.get(this.whatsappulr,{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

   }


   getServiceTypeDDL()
   {
    return new Promise((resolve, reject) => {
      this.http.get(this.url+'getServiceTypeDDL')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   register(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveParlorVisit', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

 
   ResetPassword(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'ResetPassword', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

   }

   sendOffer(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'sendOfferSMS', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getProfileData(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getProfileData', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   UpdateParlorToken(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateParlorToken', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }



   
   changepassword(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'ChangePassword', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }



   registerupdate(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateParlorRegister', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   otpGenrate(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'VerifyMobile', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   otpGenrateRegisterdMobile(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'VerifyRegisterdMobile', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getAppointmentStaffDetail(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentStaffDetail', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   
   getsupplierlist(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getsupplierlist', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

  newClient(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveClient', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getClientHistory(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getClientHistory', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   getEnquiryList(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getEnquiryList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   getfollowupList(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getClientFollowupList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   saveLocation(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateParlorLocation', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   getEvent(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getClientEventList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   makePayment(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveParlorPayment', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   sendsms(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'sendCustomSMS', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   upateMobileVer(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateMobileVerifed', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   upateProfile(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateParlorProfile', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   upateStaff(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateStaff', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   getParlorPayment(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getParlorPaymentHistory', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   getStafflistDDL(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffDDL', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   getAllService(data) {
    // console.log("request sending");
    //debugger;
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getServiceList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getStaffCommissionList(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffCommissionList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   

   SaveStaffCommission(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffCommission', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   SaveStaffAdvance(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffAdvance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   UpdateStaffAdvance(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffAdvance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   getStaffAdvanceList(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   getStaffAttendanceList(data)
   {return new Promise((resolve, reject) => {
    this.http.post(this.url+'getStaffAttendanceList', JSON.stringify(data),{
    headers: { 'Content-Type': 'application/json'}})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });

   }


   SaveStaffAttendance(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   UpdateStaffActiveStatus(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffActiveStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   getFeedback(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getFeedbackList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   UpdateStaffAdvanceRecieved(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffAdvanceRecieved', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   getStaffSalaryList2(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffSalaryList2', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   getStaffSalaryWithSummeryByYear(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffSalarySummeryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   submitFeedback(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveFeedback', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   UpdateParlorExpences(data)
   {
    
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateParlorExpences', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   updateService(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateServiceRate', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   getServiceDDL(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getServiceDDL', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   getServiceCategoryDDL(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getServiceCategoryDDL', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   
   
   getServiceCat() {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.get(this.url+'getServiceCategoryList')
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   getServiceCategory(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
      this.http.post(this.url+'getServiceCategoryList2', JSON.stringify(data),{
        headers: { 'Content-Type': 'application/json'}})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
     });
   }

   getParlorTypesList()
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorTypesList', {
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   
   getState() {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.get(this.url+'getStateList')
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getpayment() {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.get(this.url+'getPaymentModeList')
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }




   addService(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveService', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   getClinetbyMob(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getClientByMobileNo', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   addApointment(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
      
       this.http.post(this.url+'SaveAppointment2', JSON.stringify(data),{
        //this.http.post(this.url+'SaveAppointment', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   addBill(data) {
    console.log(data);
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveBill', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   billEntry(data) {
    console.log(data);
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveBill2', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   billEntryGST(data) {
    console.log(data);
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveBill4', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   billEntry_(data) {
    console.log(data);
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveBill3', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   SaveEnquiry(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveEnquiry', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


  getApointment(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getAppointmentList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getOnlineBookingNotification(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getNotificationList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getParlorHolidaySchedule(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getParlorHoidayList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   Apointmentupdate(data) {
    console.log(JSON.stringify(data));
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateAppointmentStatus', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
  //  UpdateAppointmentPostpone


  Apointmentpostpond(data) {
    // console.log("request sending");

     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateAppointmentPostpone', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   OnlineAppointmentPostponed(data) {
    // console.log("request sending");

     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateAppointmentPostpone', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   OnlineAppointmentUpdate(data) {
    console.log(JSON.stringify(data));
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateAppointmentStatus', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getClientlist(data) {
    // console.log("request sending");

     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getClientList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   


   getStafflist(data) {
    // console.log("request sending");

     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getStaffList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getCollectionReport(data) {
    // console.log("request sending");

     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getBillSummery', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getStaffSearchResult(data){

 return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffListSearch', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   getSearchResult(data)
   {
     
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientListSearch', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   getSearchResult2(data)
   {
     
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientListSearch2', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

  


   

   UpdateClientActiveStatus(data)
   {

    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateClientActiveStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   updateclient(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateClient', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   getStaffAdvanceById(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceById', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   saveAdvanceRecieve(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffAdvanceRecieved', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   updateAdvanceReceived(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffAdvanceRecieved', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   getSalaryDetails(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffSalaryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


   
   saveSalary(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffSalary', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   updateSalary(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffSalary', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   getStaticKeywordsList(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaticKeywordsList',JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   addExpence(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveParlorExpences',JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   getSupplierById(data)
    {
  return new Promise((resolve, reject) => {
    this.http.post(this.url+'getSupplierById', JSON.stringify(data),{
    headers: { 'Content-Type': 'application/json'}})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });
  }



  addSupplier(data)
  {
  return new Promise((resolve, reject) => {
    this.http.post(this.url+'SaveSupplier', JSON.stringify(data),{
    headers: { 'Content-Type': 'application/json'}})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });
  }


  upateSupplier(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateSupplier', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getSupplierlistDDL(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getSupplierDDL', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  SaveProductPurchase(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveProductPurchase', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getParlorExpencesList(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorExpencesSummery', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffAdvanceRecievedList(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceRecievedList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getProductPurchaseList(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getProductPurchaseList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }


  getStaffSalaryList(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffSalaryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateProductPurchase(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateProductPurchase', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  getStaffBusyList(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffBusyList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }



  getStaffCommissionDetailList(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffCommissionDetailList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateParlorTimings(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateParlorTimings', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  SaveParlorTime(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveParlorTimmings', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  SaveParlorHoliday(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveParlorHoliday', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateSupplierActiveStatus(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateSupplierActiveStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }



  UpdateFeedbackIsShow(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateFeedbackIsShow', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  getStaffAttendanceListOnly(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAttendanceListOnly', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateServiceConfiguration(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateServiceConfiguration', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }
  SaveGallery(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveGallery', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }
  getGalleryList(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getGalleryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  SaveOffer(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveOffer', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }


  UpdateOffer(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateOffer', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateGalleryShowStatus(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateGalleryShowStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }


  UpdateGalleryActiveStatus(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateGalleryActiveStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateOfferActiveStatus(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateOfferActiveStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateShowParlorRate(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateParlorShowRate', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateParlorFacilities(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveParlorFacilities', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }


  getOfferList(data){
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getOfferList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }


  getClientCategoryDDL(){
    return new Promise((resolve, reject) => {
      this.http.get(this.url+'getClientCategoryDDL')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  getClientPaymentModeList()
  {
    return new Promise((resolve, reject) => {
      this.http.get(this.url+'getClientPaymentModeList')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientAgeGroupDDL()
  {
    return new Promise((resolve, reject) => {
      this.http.get(this.url+'getClientAgeGroupDDL')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateParlorCloseDate(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateParlorCloseDate', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  getOfferImageList()
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getOfferImageList', {
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getOnlineEnquiryList(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getOnlineEnquiryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateEnquiryAction(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateEnquiryAction', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  UpdateAboutParlour(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateParlorAbout', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  getReminderReport(data) {
    // console.log("request sending");

    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getBillReminderDetail', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  UpdateParlourType(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateParlorParlorType', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }


  addUser(data)
  {
  return new Promise((resolve, reject) => {
    this.http.post(this.url+'SaveParlorUser', JSON.stringify(data),{
    headers: { 'Content-Type': 'application/json'}})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });

  }

  getUserList(data) {
    // console.log("request sending");

    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorUserList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateUser(data)
  {
  return new Promise((resolve, reject) => {
    this.http.post(this.url+'UpdateParlorUser', JSON.stringify(data),{
    headers: { 'Content-Type': 'application/json'}})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });

  }

  deleteUser(data)
  {
  return new Promise((resolve, reject) => {
    this.http.post(this.url+'DeleteParlorUser', JSON.stringify(data),{
    headers: { 'Content-Type': 'application/json'}})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  });

  }


  //New API


  getDashboardCounts(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getDashboardCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientCategoryList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCategoryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientCategoryList2(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCategoryList2', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  getClientClassificationList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientClassificationList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientClassificationList2(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientClassificationList2', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  getClientList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientList1(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientList2', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  getClientAppointmentHistory(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientAppointmentHistory', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientBillHistory(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientBillHistory', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }



  getAppointmentList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }



  getOfferPhotos(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getOfferPhotoList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  saveOfferPhotos(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveOfferPhoto', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateOfferPhotoStatus(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateOfferPhotoActiveStatus', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  //Data Analysis api
  getAnalysisData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getSpecialCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getServiceCategoryData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getServiceCategoryCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getServiceSubCategoryData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getServiceDetailCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getServiceClientData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getServiceClientCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getAppointmentData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentDetailCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getAppointmentClientData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentClientCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  
  getAppointmentNewClientData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentClientCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getFeedbackData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getFeedbackDetailCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getFeedbackClientData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getFeedbackClientCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientServicesData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getBillListByClientID', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientServicesDetails(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getBillByID', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientAppointmentsData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentListByClientID', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientAppointmentDetails(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAppointmentByID', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientFeedbackData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getFeedbackListByClientID', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  //Grouping API Call

  createParlorGroup(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveParlorGroup', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  transferGroupClient(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'TransferParlorGroupClient', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  TransferParlorGroupClientMulti(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'TransferParlorGroupClientMulti', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  SaveParlorGroupHistory(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveParlorGroupHistory', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getParlorGroupHistory(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorGroupHistory', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getParlorClientList(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorGroupParticipent', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getParlorGroupDDL(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorGroupDDL', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getParlorGroupClientDDL(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getParlorGroupDDL', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  generateParlorGroups(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'GenrateParlorGroups', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  //Staff Module

  getSaveUserAttendanceData(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffSelfAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getUpdateUserAttendanceData(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffSelfAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });

  }

  getStaffTodaysLoginData(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffSingleAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  newStaffInOutTime(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffSingleAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateStaffInOutTime(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffSingleAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  createSalaryStructure(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffSalaryStructure', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateSalaryStructure(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffSalaryStructure', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  checkSalaryStructure(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffSalaryStructureList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  createSalary(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveStaffSalary', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateStaffSalary(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffSalary', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  getStaffAttendanceDetails(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAttendanceListOnly', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffCommissionDetails(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffCommissionDetailList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateStaffCommissionDetails(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffCommissionDetail', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffAdvanceDetails(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceTransactionList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateStaffAdvanceDetailAdmin(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffSingleAttendance', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  getStaffAdvanceListData(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffCurrentAdvanceListData(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffCurrentAdvanceList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffAdvanceSummaryListData(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceSummery', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

   getStaffAdvanceReturnListData(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffAdvanceRecievedList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  
  getServiceUseByClientData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientServiceCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

   getClientAdvanceData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCurrentAdvanceList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

   getClientCreditData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCurrentCreditList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  saveClientAdvance(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveClientPayment', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  saveClientAdvance1(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveClientPayment2', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  saveClientCredit(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'SaveClientPayment', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  //Dashboard Payment related list
  getCollectionByPaymentType(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientPaymentList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getCollectionByPaymentMode(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientPaymentModesSummery', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getCreditClientList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCreditList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getCurrentAdvanceList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCurrentAdvanceList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getCurrentCreditList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCurrentCreditList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffCommissionReport(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getStaffCommissionDetailList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }


  getClientUsedAdvanceList(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientUsedAdvanceList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  saveServiceCommission(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateStaffCommissionList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientCreditHistory(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientCreditHistoryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getClientAdvanceHistory(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientAdvanceHistoryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getStaffScheduleDetails(data)
  {
    return new Promise((resolve, reject) => {
       //this.http.post(this.url+'getDailyScheduleList', JSON.stringify(data),{
        this.http.post(this.url+'getDailyScheduleList2', JSON.stringify(data),{
      
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getAllServiceCategoryDDL(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getAllServiceCategoryList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   //Product API Calls

      
   
   getProductCompany(data) {
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getProductCompanyList', JSON.stringify(data),{
        headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getProductCompany_(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getProductCompanyDDL', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   getProductUnits() {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getProductUnitDDL','')
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

   getProductByCompany(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getProductList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   addProduct(data) {
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveProduct', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   editProduct(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateProduct', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getSupplierList_(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getSupplierList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   saveSupplier(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveSupplier', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   updateSupplier(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateSupplier', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   supplierActiveStatus(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateSupplierActiveStatus', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   supplierPaymentHistory(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getSupplierBalanceList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   
   supplierPaymentDetail(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getSupplierPaymentList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getSupplierBillingData(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getSupplierBillingList', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   

   getSupplierBillingDetail(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getSupplierBillingDetail', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   
   getProductStockAnalysis(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getProductStockAnalysisList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getProductSaleAnalysis(data) {
    // console.log("request sending");
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getProductSalesAnalysisList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  getGSTSetting(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'getProfileData', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   updateGSTSetting(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateParlorGSTSetting', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   UpdateCatagoryGSTSetting(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateCatagoryGSTSetting', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   UpdateCompanyGSTSetting(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateCompanyGSTSetting', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }


   updateDiscount(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateParlorDiscountPercent', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   
   updateCompanyDiscount(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateProductCompanyDiscountPercent', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   

   updateServiceCategoryDiscount(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'UpdateServiceCategoryDiscountPercent', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }
   

   getSupplierListSearch(data)
   {
     
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getSupplierListSearch', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }
   
   addProductPurchase(data)
   {
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveProductPurchase', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getClientProductAnalysisData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getClientProductCount', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  UpdateProductConfiguration(data)
  {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'UpdateProductConfiguration', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }

  usedProductSave(data) {
    console.log(data);
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveProductUsage', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   getProductUsageList(data)
   {
    return new Promise((resolve, reject) => {
      this.http.post(this.url+'getProductUsageList', JSON.stringify(data),{
      headers: { 'Content-Type': 'application/json'}})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
   }

   saveSupplierPayment(data) {
    console.log(data);
     return new Promise((resolve, reject) => {
       this.http.post(this.url+'SaveSupplierPayment', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   addApointmentGST(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
      
       this.http.post(this.url+'SaveAppointment3', JSON.stringify(data),{
        //this.http.post(this.url+'SaveAppointment', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

   staffCommissionSummery	(data) {
    // console.log("request sending");
     return new Promise((resolve, reject) => {
      
       this.http.post(this.url+'getStaffCommissionDetailList', JSON.stringify(data),{
        //this.http.post(this.url+'SaveAppointment', JSON.stringify(data),{
       headers: { 'Content-Type': 'application/json'}})
         .subscribe(res => {
           resolve(res);
         }, (err) => {
           reject(err);
         });
     });
   }

 

  // <domain includeSubdomains="true">localhost</domain>
  // <domain includeSubdomains="true">testservice.preshitcreations.com</domain>
  // <domain includeSubdomains="true">preshitwebservices.preshitcreations.com</domain>
  //<domain includeSubdomains="true">beautyImageApi.preshitcreations.com</domain>
}