import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ParlorService } from '../parlor.service';
import { RestService } from '../../rest.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../login.service';
import { Storage } from '@ionic/storage';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-service-staff-assign-popup',
  templateUrl: './service-staff-assign-popup.page.html',
  styleUrls: ['./service-staff-assign-popup.page.scss'],
})
export class ServiceStaffAssignPopupPage implements OnInit {

  serviceName="";
  serviceAmount=0;
  selectedStaffList:any[]=[];
  selectedStaff:any;

  staffRequest = {
    Parlor_Id: "",
  };
  staffResponse: any;
  staffList:any[]=[];
  staffData:any[]=[];

  serviceStaffReturn=
  {
    //serviceAmount:this.serviceAmount,
    selectedStaff:this.selectedStaffList
  }

  staffName:any="";

  constructor(private loginService: LoginService, 
    public modalController: ModalController,
    public menuCtrl: MenuController, 
    public storage: Storage, 
    private fb: FormBuilder, 
    private rest: RestService,
    public loadingService: LaodlingService, 
    private toastService: ToastService, 
    public datePipe: DatePipe, 
    private restService: RestService, 
    private navController: NavController, 
    private loadingController: LoadingController,
    private parlorService: ParlorService) { }

  ngOnInit() {
  }

  ionViewDidEnter() {

    this.loginService.load();
    this.staffRequest.Parlor_Id = this.loginService.login.Parlor_Id;
    this.getStaffList();
//debugger;
    // this.staffData=this.loginService.login.Staff_List;
    // for (let staffItem of this.staffData) {
    //   let temp = {
    //     Staff_Name: staffItem.Staff_Name,
    //     Staff_Id: staffItem.Staff_Id,
    //     isCheck:false
    //   };
    //   this.staffList.push(temp);
    // }



    // return this.storage.get("comeFromdetails").then((val) => {
    //   //debugger;
    //   if (val) {
    //     //debugger;
    //     this.comefrom = val;
    //     if (this.comefrom == 1) {
    //       return this.storage.get("appointment").then((val) => {
    //         if (val) {
    //           // debugger;
    //           this.cat = "";
    //           this.ser = "";
    //           this.amount = 0;
    //           this.cat = "";
    //           this.ser = "";
    //           this.amount = 0;
    //           console.log(val);
    //           let appointment = val;
    //           this.clientByMobileRequest.Mobile_No = appointment.Client_Data.Mobile_No;
    //           this.advanceAmount = appointment.Client_Data.AdvanceAmount;
    //           this.dueAmount = appointment.Client_Data.DueAmount;
    //           //this.doa=appointment.Appointment_Date;
    //           this.servicetable = appointment.Appointment_Service_List;
    //           //this.requestappointment.newBill.Bill_Service_List= this.servicetable;
    //           for (let st of this.servicetable) {
    //             this.requestappointment.newBill.Bill_Service_List.push(st);
    //           }
    //           this.calculateTotal();

    //           if (this.netPayableAmount < 0) {
    //             this.isPendingAmount = true;
    //             this.isAdvanceAmount = true;

    //             this.currentAdvanceAmount = parseInt(this.advanceAmount) - (this.totalamount + parseInt(this.dueAmount) - this.discountAmount)
    //           }
    //           this.requestappointment.newBill.Appointment_Entry_Id = appointment.Appointment_Entry_Id;
    //           this.requestBillEntry.newBill.Appointment_Entry_Id = appointment.Appointment_Entry_Id;
    //           this.storage.set("comeFromdetails", 0);
    //           this.isFromAppointment = true;
    //         }
    //       });
    //     }
    //     else {
    //       this.isFromAppointment = false;
    //     }
    //   }
    // });
  }

  getStaffList() {
    this.rest.getStafflistDDL(this.staffRequest).then((data) => {
      // this.load.present1();
      console.log(data);
      this.staffResponse = data;

      console.log(this.staffResponse.getStaffDDLResult);

      for (let staffItem of this.staffResponse.getStaffDDLResult) {
        let temp = {
          Staff_Name: staffItem.Staff_Name,
          Staff_Id: staffItem.Staff_Id,
          isCheck:false
        };
        this.staffList.push(temp);
      }

      console.log(this.staffList);
    });
  }

  checkSelect(event, item) {
    let isChecked = event.target.checked;
    if (isChecked) {
      let i = 0;
      
    }

  }

  addServiceStaff()
  {
    for (let staffItem of this.staffList) {
      if(staffItem.isCheck)
      {
        this.selectedStaffList.push(staffItem);
      }
     
    }
    //this.serviceStaffReturn.serviceAmount=this.serviceAmount;
    this.serviceStaffReturn.selectedStaff=this.selectedStaffList;
    this.modalController.dismiss(this.serviceStaffReturn);
  }

  close() {
    this.modalController.dismiss(this.serviceStaffReturn);
  }

  }




