import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { RestService } from "../../rest.service";
import { ParlorService } from "../parlor.service";
import { Storage } from "@ionic/storage";
import { ToastService } from "../toast.service";
import { LaodlingService } from "../laodling.service";
import { LoginService } from "../login.service";
import { DatePipe } from "@angular/common";
import { LoadingController } from '@ionic/angular';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { AlertController } from "@ionic/angular";
import { NgZone } from '@angular/core';
import { MiddleToastService } from './../middle-toast.service';
import * as moment from "moment";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ServiceStaffAssignPopupPage } from './../service-staff-assign-popup/service-staff-assign-popup.page';
import { analyzeAndValidateNgModules, analyzeFileForInjectables } from "@angular/compiler";
import { MenuController } from '@ionic/angular';




@Component({
  selector: 'app-billinggstproduct',
  templateUrl: './billinggstproduct.page.html',
  styleUrls: ['./billinggstproduct.page.scss'],
})
export class BillinggstproductPage implements OnInit {

  gstServiceType: any = "";
  gstServicePercentage: any = 0;
  servicecategory: any;

  isCash: any = false;
  isOnline: any = false;
  isCashOnline: any = false;
  isPendingAmount: any = false;
  isCreditAmount: any = false;
  isAdvanceAmount: any = false;
  showButton: any = true;

  cash: any = 0;
  gPay: any = 0;
  phonePay: any = 0;
  paytm: any = 0;
  other: any = 0;
  voucher: any = 0;

  dueAmount: any = 0;
  advanceAmount: any = 0;

  currentDueAmount: any = 0;
  currentAdvanceAmount: any = 0;

  staffRequest = {
    Parlor_Id: "",
  };
  staffResponse: any;
  staffServiceList: any[] = [];
  serviceDisplay: any[] = [];
  displayStaff: any;
 
  requestBillEntry = {
    newBill: {
      Parlor_Id: "",
      Client_Id: "",
      Bill_Date: "",
      Discount_Amount: 0,
      Net_Amount: 0,//Gross-discount
      Total_Amount: 0,//Gross Amount
      is_GST_Included: "True",
      IGST: 0,
      SGST: 0,
      CGST: 0,
      Appointment_Entry_Id: 0,
      Payment_Mode_Id: "",
      Remark: "",
      Advance_Amount: 0,
      Bill_Type: "",
      Mobile_No: "",
      Bill_Service_List: [],
      Bill_Staff_List: [],
      Bill_Product_List: [],
      Bill_Remainder_List: [],
      Client_Payment_Mode_List: [],
      Due_Amount: 0,
      Credit_Amount: 0,
      Used_Advance: 0,
    },

  };
  billEntryResponse: any;

  requestProfileData = {
    Parlor_Id: ''
  }
  profileData: any;

  serviceCategoryRequest = {
    Parlor_Id: "",
    Service_Category_Id: "0",
  };
  serviceCategoryResponse: any;



  shareMessage: any;

  parlorInfo: any;
  bannerImageURL: any;
  planId: any;
  billEntryMessage: any
  ParlorId: any;
  currentTime: any;
  categoryName: any;
  subCategoryName: any;


  category: any;
  agree1 = false;

  load: any;
  clientByMobileRequest = {
    Parlor_Id: "",
    Mobile_No: "",
  };
  clientByMobileResponse: any;

  maxDate1 = "1234";
  searchRequest = {
    Parlor_Id: "",
    searchText: "",
  };

  searchResponse: any;
  isItemAvailable = false;
  isPopupShow = false;
  isClear = false;
  isSelectedItem = false;
  items: any;

  requestappointment = {
    newBill: {
      Parlor_Id: "",
      Mobile_No: "",
      Client_Id: "",
      Bill_Date: "",
      is_GST_Included: "True",
      IGST: 0,
      SGST: 0,
      CGST: 0,
      Discount_Amount: 0,
      Total_Amount: 0,
      Net_Amount: 0,
      Appointment_Entry_Id: 0,
      Bill_Service_List: [],
      Bill_Remainder_List: [],
      Payment_Mode_Id: "",
      Remark: "",
    },
  };
  paymentmodeResponse: any;
  paymentmodes: any[] = [];
  serviceTimeString: any;
  serviceTime: any;
  doa: any;
  cat: any;
  ser: any;
  response: any;
  services: any;
  serviceStartTime: any;
  serviceStopTime: any;
  amount = 0.0;
  sername: any;
  totalamount = 0.0;
  totalTaxbalAmount = 0.0;
  totalTaxAmount = 0.0;
  servicetable: any[] = [];
  clientName: any;
  client: any;

  comefrom: any;
  grossAmount: any = 0;

  reminderData = {
    Bill_Remainder_Name: "",
    Bill_Remainder_Date: ""
  }

  requestCreateCustomerData = {
    newClient: {
      Parlor_Id: "",
      Gender: "",
      Client_Code: "CL1001",
      Client_Name: "",
      Mobile_No: "",
      Address: "",
      Email: "",
      Photo_URL: "",
      Birth_Date: "",
      Anniversary_Date: null,
      Refered_By: 0,
      Client_Category_ID: null,
      City: "",
      Client_From: "App",
    },
  };

  requestCreateServiceData = {
    newService:
    {
      Service_Catagory_ID: "",
      Parlor_Id: "",
      Service_Name: "",
      Service_Rate: "",
      Description: "",
      Service_Time: "",
    }
  }

  createClientResponse: any;
  isCategorySelect: boolean = false;
  lastVisitedDate: any = "--";
  lastBillAmount: any = "--";
  totalCollection: any = "--";
  numberOfVisit: any = "--";
  daysOfLastVisit: any = "--";
  age: any = "--";
  city: any = "--";
  clientType: any = "--";
  clientZone: any = "--";
  isGreen: boolean = false;
  isYellow: boolean = false;
  isRed: boolean = false;
  isGray: boolean = false;
  isReminder1: boolean = false;
  isReminder2: boolean = false;
  isReminder3: boolean = false;
  reminderDate1: any;
  reminderDate2: any;
  reminderDate3: any;
  reminder1Day: any;
  reminder2Day: any;
  reminder3Day: any;
  isCheckBoxReminder1Checked: boolean = false;
  isCheckBoxReminder2Checked: boolean = false;
  isCheckBoxReminder2Disable: boolean = true;
  isCheckBoxReminder3Checked: boolean = false;
  isCheckBoxReminder3Disable: boolean = true;

  billReminderCount: any = 0;

  getServiceCategoryDDLRequest = {
    Parlor_Id: "",
  };

  counter = 1;
  isEnabled: any = true;
  staffList: any[] = [];
  staff_id = 0;
  staffBusyList: any[] = [];


  validation_msg: any;
  maxDate: any;

  requiredTime: any;

  discountAmount: any = 0;
  additionalDiscountAmount: any = 0;
  netPayableAmount: any = 0;
  paymentModeId: any = 3;
  remarkContent: any;

  isFromAppointment: boolean = false;

  serviceCategoryWithCheck: any[] = [];
  isSameCash: boolean = false;
  allFieldRequired: boolean = true;

  //new logic
  categoryDDLRequest = {
    Parlor_Id: 0,
  };
  categoryDDLResponse: any;
  categoryDDL: any[] = [];
  singleStaffToAllService: boolean = false;
  multipleStaffToAllService: boolean = false;
  selectedServiceStaffData: any[] = [];
  categoryServiceArray: any[] = [];
  selectedCategory: any[] = [];
  serviceCategoryRequest_ = {
    Parlor_Id: 0,
  };
  serviceCategotyResponse: any;
  serviceCategoty: any[] = [];
  isSingleStaffSelected: boolean = false;
  isMultipleStaffSelected: boolean = true;
  returnBasicRate: any;

  productList: any[] = [];

  serviceBillingAmount:any;
  serviceDiscountAmount:any;
  servicePaybalAmount:any;
  serviceTaxableAmount:any;
  serviceTaxAmount:any;



  //product
  IsService: boolean = true;

  companyData: any;
  companyDataList: any;
  selectedCompany: any;
  companyProductArray: any[] = [];
  selectedProductData: any[] = [];
  productTable: any[] = [];

  productBillingAmount:any=0;
  productDiscountAmount:any=0;
  productPaybalAmount:any=0;
  productTaxableAmount:any=0;
  productTaxAmount:any=0;



  getCompanyDDLRequest = {
    Parlor_Id: "",
  };

  parlorGSTType:any;
  parlorGSTPercent:any;
  parlorDiscountPer:any;

  constructor(
    private fb: FormBuilder,
    public datepipe: DatePipe,
    public middleToastService: MiddleToastService,
    public alertController: AlertController,
    public ngZone: NgZone,
    private loginervice: LoginService,
    private socialSharing: SocialSharing,
    private loader: LoadingController,
    private toast: ToastService,
    private storage: Storage,
    private parlor: ParlorService,
    private navCtrl: NavController,
    public modalController: ModalController,
    private rest: RestService
  ) {
    this.maxDate = new Date().toISOString();
    this.searchRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.billEntryMessage = this.loginervice.login.billEntryMsg;
    this.ParlorId = this.loginervice.login.Parlor_Id;
    this.doa = new Date().toISOString();

  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.category = [];
    this.serviceCategoty = [];
    this.selectedServiceStaffData = [];
    this.categoryServiceArray = [];
    this.selectedCategory = [];
    this.categoryDDL = [];
    this.serviceCategoryWithCheck = [];
  }

  ionViewDidEnter() {
//debugger;
    this.loginervice.load();
    this.getServiceCategoryDDLRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.getCompanyDDLRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.requestCreateCustomerData.newClient.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.requestCreateServiceData.newService.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.requestCreateServiceData.newService.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.requestBillEntry.newBill.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.staffRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.maxDate1 = moment().format("YYYYMMDDHHmmSSms");
    console.log("Current Date : " + moment().format("'DD/MM/yyyy'"))
    this.searchRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.serviceCategoryRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.serviceCategoryRequest_.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.categoryDDLRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.requestappointment.newBill.Parlor_Id = this.loginervice.login.Parlor_Id;

    this.clientByMobileRequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.requestProfileData.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.parlorInfo = this.loginervice.login.parlorInfo;
    this.bannerImageURL = this.loginervice.login.bannerImage_URL;
    this.cat = "";
    this.ser = "";
    this.currentTime = new Date().getTime();
    this.amount = 0;
    
    this.parlorGSTType = this.loginervice.login.IsServiceGST;
    this.parlorGSTPercent = this.loginervice.login.ServiceGSTPercentage;
    this.parlorDiscountPer = this.loginervice.login.Discount_Percent;

    this.getProfileData();
    this.getAllServiceCat();
    this.getCompanyList();
    this.getClientPaymentModeList();
    //this.getAllCategory();
    this.getStaffList()
    //this.getAllCategoryWithService();
    return this.storage.get("comeFromdetails").then((val) => {
      //debugger;
      if (val) {
        //debugger;
        this.comefrom = val;
        if (this.comefrom == 1) {
          return this.storage.get("appointment").then((val) => {
            if (val) {
              // debugger;
              this.cat = "";
              this.ser = "";
              this.amount = 0;
              this.cat = "";
              this.ser = "";
              this.amount = 0;
              console.log(val);
              let appointment = val;
              this.clientByMobileRequest.Mobile_No = appointment.Client_Data.Mobile_No;
              this.advanceAmount = appointment.Client_Data.AdvanceAmount;
              this.dueAmount = appointment.Client_Data.DueAmount;
              //this.doa=appointment.Appointment_Date;
              this.servicetable = appointment.Appointment_Service_List;
              //this.requestappointment.newBill.Bill_Service_List= this.servicetable;
              for (let st of this.servicetable) {
                this.requestappointment.newBill.Bill_Service_List.push(st);
              }
              this.calculateTotal();

              if (this.netPayableAmount < 0) {
                this.isPendingAmount = true;
                this.isAdvanceAmount = true;

                this.currentAdvanceAmount = parseInt(this.advanceAmount) - (this.totalamount + parseInt(this.dueAmount) - this.discountAmount)
              }
              this.requestappointment.newBill.Appointment_Entry_Id = appointment.Appointment_Entry_Id;
              this.requestBillEntry.newBill.Appointment_Entry_Id = appointment.Appointment_Entry_Id;
              this.storage.set("comeFromdetails", 0);
              this.isFromAppointment = true;
            }
          });
        }
        else {
          this.isFromAppointment = false;
        }
      }
    });
  }

  getProfileData() {
    this.rest.getProfileData(this.requestProfileData).then(data => {
      console.log(
        data
      );
      // debugger;
      this.profileData = data;
      this.parlorInfo = "*" + this.profileData.getProfileDataResult.Parlor_Name + "*-" + this.profileData.getProfileDataResult.Mobile_No
        + ",\nAddress - " + this.profileData.getProfileDataResult.Address;
      this.planId = this.profileData.getProfileDataResult.Plan_Id;
      if (this.profileData.getProfileDataResult.is_Banner_Exist == "True") {
        this.bannerImageURL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner" + this.ParlorId + ".jpg";
      }
      else {
        this.bannerImageURL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner.jpg";
      }
      this.billReminderCount = this.profileData.getProfileDataResult.Bill_Remainder_Count;


      if (this.billReminderCount == 1) {
        this.reminder1Day = 10;
        var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(10, 'days');
        this.reminderDate1 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
        this.isReminder1 = true;
        this.isReminder2 = false;
        this.isReminder3 = false;
      }
      else if (this.billReminderCount == 2) {
        // this.reminderDate1 =new Date(new Date().getTime()+(864000000)).toISOString();
        // this.reminderDate2 =new Date(new Date().getTime()+(1728000000)).toISOString();

        this.isReminder1 = true;
        this.isReminder2 = true;
        this.isReminder3 = false;
      }
      else if (this.billReminderCount == 3) {
        // this.reminderDate1 =new Date(new Date().getTime()+(864000000)).toISOString();
        // this.reminderDate2 =new Date(new Date().getTime()+(1728000000)).toISOString();
        // this.reminderDate3 =new Date(new Date().getTime()+(2592000000)).toISOString();

        this.isReminder1 = true;
        this.isReminder2 = true;
        this.isReminder3 = true;
      }
    })
  }

  getAllServiceCat() {
    this.categoryDDL = [];
    this.rest
      .getServiceCategoryDDL(this.getServiceCategoryDDLRequest)
      .then((data) => {
        // debugger;
        this.servicecategory = data;

        this.categoryDDL = this.servicecategory.getServiceCategoryDDLResult;
        console.log(
          "service"
        );
        console.log(
          this.categoryDDL
        );
      });
  }

  getClientPaymentModeList() {

    // let temp = {
    //   Payment_Mode_Id: 1,
    //   Payment_Mode_Name: "Cash"

    // };
    // let temp1 = {
    //   Payment_Mode_Id: 2,
    //   Payment_Mode_Name: "Online"

    // };
    let temp2 = {
      Payment_Mode_Id: 3,
      Payment_Mode_Name: "Cash-Online"

    };
    let temp3 = {
      Payment_Mode_Id: 5,
      Payment_Mode_Name: "Credit"

    };
    // this.paymentmodes.push(temp)
    // this.paymentmodes.push(temp1)
    this.paymentmodes.push(temp2)
    this.paymentmodes.push(temp3)
    // this.rest.getClientPaymentModeList().then((data) => {
    //   console.log("Payment mode data ::::")
    //   console.log(data)
    //   this.paymentmodeResponse = data;
    //   this.paymentmodes = this.paymentmodeResponse.getClientPaymentModeListResult;
    // });
  }


  findClient() {
    console.log("hiiiiii");
    if (this.clientByMobileRequest.Mobile_No.length == 10) {
      this.rest.getClinetbyMob(this.clientByMobileRequest).then((data) => {
        this.clientByMobileResponse = data;
        console.log(this.clientByMobileResponse);
        this.clientName = this.clientByMobileResponse.getClientByMobileNoResult.Client_Name;
        this.lastVisitedDate = this.clientByMobileResponse.getClientByMobileNoResult.Last_Visited_Date == null ? "-" : this.clientByMobileResponse.getClientByMobileNoResult.Last_Visited_Date;
        this.lastBillAmount = this.clientByMobileResponse.getClientByMobileNoResult.Last_Billed_Amount == null ? "-" : this.clientByMobileResponse.getClientByMobileNoResult.Last_Billed_Amount;

        this.totalCollection = this.clientByMobileResponse.getClientByMobileNoResult.TotalCollection == null ? "-" : this.clientByMobileResponse.getClientByMobileNoResult.TotalCollection;
        this.numberOfVisit = this.clientByMobileResponse.getClientByMobileNoResult.NoOfVisits == null ? "-" : this.clientByMobileResponse.getClientByMobileNoResult.NoOfVisits;
        this.daysOfLastVisit = this.clientByMobileResponse.getClientByMobileNoResult.DaysOfLastVisit == null ? "-" : this.clientByMobileResponse.getClientByMobileNoResult.DaysOfLastVisit;
        this.requestappointment.newBill.Client_Id = this.clientByMobileResponse.getClientByMobileNoResult.Client_Id;
        this.requestBillEntry.newBill.Client_Id = this.clientByMobileResponse.getClientByMobileNoResult.Client_Id;
        this.age = this.clientByMobileResponse.getClientByMobileNoResult.Client_Age == null ? "--" : this.clientByMobileResponse.getClientByMobileNoResult.Client_Age;
        this.city = this.clientByMobileResponse.getClientByMobileNoResult.City == null ? "--" : this.clientByMobileResponse.getClientByMobileNoResult.City;
        this.clientType = this.clientByMobileResponse.getClientByMobileNoResult.Client_Category_Name == "Moderate" ? "M" :
          this.clientByMobileResponse.getClientByMobileNoResult.Client_Category_Name == "Premium" ? "P" : "G";
        this.clientZone = this.clientByMobileResponse.getClientByMobileNoResult.ClientClassification;

        this.isGreen = this.clientZone == "Green" ? true : false;
        this.isRed = this.clientZone == "Red" ? true : false;
        this.isYellow = this.clientZone == "Yellow" ? true : false;
        this.isGray = this.clientZone == "Grey" ? true : false;
        this.dueAmount = this.clientByMobileResponse.getClientByMobileNoResult.DueAmount == null ? "0" : this.clientByMobileResponse.getClientByMobileNoResult.DueAmount;
        this.advanceAmount = this.clientByMobileResponse.getClientByMobileNoResult.AdvanceAmount == null ? "0" : this.clientByMobileResponse.getClientByMobileNoResult.AdvanceAmount;
      });
    }
  }


  validateData() {
    this.allFieldRequired = false;
    if (this.clientByMobileRequest.Mobile_No != "") {
      this.allFieldRequired = true;
    }
  }

  Back() {

    this.navCtrl.setDirection("root");
    //this.navCtrl.navigateRoot("");
    this.navCtrl.back();
  }

  ionViewWillLeave() {
    this.load.dismiss();
  }

  async createClient() {
    const alert = await this.alertController.create({
      header: 'Create Client',
      inputs: [

        {
          label: 'Mobile Number',
          name: 'mobileNumber',
          type: 'number',
          value: this.searchRequest.searchText,
          placeholder: 'Enter mobile number'

        },
        {
          label: 'Customer name',
          name: 'customerName',
          type: 'text',
          placeholder: 'Enter customer name'
        },
        {
          label: 'City',
          name: 'city',
          type: 'text',
          placeholder: 'Enter city'
        },
        {
          label: 'Date of Birth',
          name: 'dob',
          type: 'date',
          placeholder: 'Enter Date of Birth'
        },
      ],

      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Ok',
          handler: data => {
            console.log(data.reason);
            if (data.customerName != "") {
              this.requestCreateCustomerData.newClient.Client_Name = data.customerName;
            }
            else {
              this.middleToastService.show("Please enter client name");
              return false;
            }
            if (data.mobileNumber != "") {
              if (data.mobileNumber.length == 10) {
                this.requestCreateCustomerData.newClient.Mobile_No = data.mobileNumber;
              }
              else {
                this.middleToastService.show("Please enter valid mobile number");
                return false;
              }
            }
            else {
              this.middleToastService.show("Please enter mobile number");
              return false;
            }
            if (data.city != "") {
              this.requestCreateCustomerData.newClient.City = data.city;
            }
            else {
              this.middleToastService.show("Please enter city");
              return false;
            }

            if (data.dob != "") {
              this.requestCreateCustomerData.newClient.Birth_Date = this.datepipe.transform(data.dob, "dd/MM/yyyy");
            }
            else {
              this.requestCreateCustomerData.newClient.Birth_Date = null;
            }

            console.log(this.requestCreateCustomerData);
            this.rest.newClient(this.requestCreateCustomerData).then((data) => {
              this.createClientResponse = data;
              console.log(this.createClientResponse);
              if (this.createClientResponse.SaveClientResult.Result == "Success") {
                this.toast.show("Client Added successfully");
                if (this.requestCreateCustomerData.newClient.Mobile_No.length == 10) {
                  this.clientByMobileRequest.Mobile_No = this.requestCreateCustomerData.newClient.Mobile_No;
                  this.rest.getClinetbyMob(this.clientByMobileRequest).then(data => {
                    this.clientByMobileResponse = data;
                    console.log(this.clientByMobileResponse);
                    this.searchRequest.searchText = "";
                    this.isItemAvailable = false;
                    this.ngZone.run(() => {
                      this.clientName = this.clientByMobileResponse.getClientByMobileNoResult.Client_Name;
                      this.lastVisitedDate = this.clientByMobileResponse.getClientByMobileNoResult.Last_Visited_Date == null ? "--" : this.clientByMobileResponse.getClientByMobileNoResult.Last_Visited_Date;
                      this.lastBillAmount = this.clientByMobileResponse.getClientByMobileNoResult.Last_Billed_Amount == null ? "--" : this.clientByMobileResponse.getClientByMobileNoResult.Last_Billed_Amount;
                      this.totalCollection = this.clientByMobileResponse.getClientByMobileNoResult.TotalCollection == null ? "--" : this.clientByMobileResponse.getClientByMobileNoResult.TotalCollection;
                      this.numberOfVisit = this.clientByMobileResponse.getClientByMobileNoResult.NoOfVisits == null ? "-" : this.clientByMobileResponse.getClientByMobileNoResult.NoOfVisits;
                      this.daysOfLastVisit = this.clientByMobileResponse.getClientByMobileNoResult.DaysOfLastVisit == null ? "--" : this.clientByMobileResponse.getClientByMobileNoResult.DaysOfLastVisit;
                      this.requestappointment.newBill.Client_Id = this.clientByMobileResponse.getClientByMobileNoResult.Client_Id;
                    });

                  })
                }
              }
              else {
                if (this.createClientResponse.SaveClientResult.Message == "Record Already Exist!") {
                  this.toast.show("Record Already Exist! ");
                }
                else {
                  this.toast.show("Client Not Added ");
                }
              }
            });
          }
        }
      ]
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    console.log(result);
  }

  async createService(schedulerData: any) {
    //debugger;
    if (this.isCategorySelect) {
      const alert = await this.alertController.create({
        header: 'Create Service',
        inputs: [

          {
            label: 'Service name',
            id: 'service-id',
            name: 'serviceName',
            type: 'text',
            placeholder: 'Enter Service Name'
          },
          {
            name: 'serviceRate',
            type: 'number',
            placeholder: 'Enter rate'
          },
          {
            name: 'serviceTime',
            type: 'number',
            placeholder: 'Enter Time in Minute only'
          },
          {
            name: 'serviceDescription',
            type: 'text',
            placeholder: 'Enter Description'
          },
        ],

        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Ok',
            handler: data => {
              console.log(data.reason);
              if (data.serviceName != "") {
                this.requestCreateServiceData.newService.Service_Name = data.serviceName;
              }
              else {
                this.middleToastService.show("Please enter service name");
                return false;
              }
              if (data.serviceRate != "") {
                this.requestCreateServiceData.newService.Service_Rate = data.serviceRate;
              }
              else {
                this.middleToastService.show("Please enter service rate");
                return false;
              }
              if (data.serviceTime != "") {
                this.requestCreateServiceData.newService.Service_Time = data.serviceTime;
              }
              else {
                this.middleToastService.show("Please enter service time");
                return false;
              }
              //if (data.serviceDescription != "") {
              this.requestCreateServiceData.newService.Description = data.serviceDescription;
              // }
              // else {
              //   this.middleToastService.show("Please enter service description");
              //   return false;
              // }

              console.log(this.requestCreateServiceData);
              this.rest.addService(this.requestCreateServiceData).then(data => {
                console.log(data);

                this.response = data;
                console.log(this.response);

                if (this.response.SaveServiceResult.Result == "Success") {

                  this.rest.getServiceDDL(this.serviceCategoryRequest).then((data) => {
                    console.log(data);
                    this.serviceCategoryResponse = data;

                    //  this.load.present1();
                    console.log(this.serviceCategoryResponse);
                    this.ngZone.run(() => {
                      this.services = this.serviceCategoryResponse.getServiceDDLResult;
                      this.serviceCategoryWithCheck = [];
                      for (let serviceCategoryItem of this.services) {
                        let tempItem = {
                          isCheck: false,
                          categoryName: serviceCategoryItem.Service_Name,
                          serviceRate: serviceCategoryItem.Service_Rate,
                          serviceRequiredTime: serviceCategoryItem.Service_Time,
                          serviceRequiredTimeString: serviceCategoryItem.Service_Time_String,
                          SubCategoryId: serviceCategoryItem.Service_ID

                        }
                        this.serviceCategoryWithCheck.push(tempItem);
                      }
                    });
                    this.toast.show("Service Added successfully");
                  });
                } else {
                  this.toast.show("Service Not Added");
                  // this.requestdat1.newService.Service_Name='';
                  //   this.requestdat1.newService.Service_Rate='';
                }
              })
            }
          }
        ]
      });
      await alert.present();
      const result = await alert.onDidDismiss();
      console.log(result);
    }
    else {
      this.toast.show("Please select Service category");
    }

  }

  checkbox1Event(e) {
    //debugger;
    if (e.currentTarget.checked) {
      this.isCheckBoxReminder2Disable = false;
      this.isCheckBoxReminder1Checked = true;
      //this.reminder1Day=10;
      // var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(10, 'days');
      // this.reminderDate1 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
    }
    else {
      this.isCheckBoxReminder2Checked = false;
      this.isCheckBoxReminder1Checked = false;
      this.isCheckBoxReminder3Checked = false
      this.isCheckBoxReminder2Disable = true;
      this.reminder1Day = "";
      this.reminderDate1 = "";
      if (this.isCheckBoxReminder3Disable == true) {
        this.isCheckBoxReminder3Disable = false;
      }
      this.isCheckBoxReminder3Disable = true;
    }
  }

  checkbox2Event(e) {
    //debugger;
    if (e.currentTarget.checked) {
      this.isCheckBoxReminder2Checked = true;
      this.isCheckBoxReminder3Disable = false;
      // this.reminder2Day=15;
      // var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(this.reminder2Day, 'days');
      // this.reminderDate2 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
      if (this.reminder2Day > this.reminder1Day) {
        this.agree1 = false;
      }
      else {
        this.agree1 = true;
      }

    } else {

      this.isCheckBoxReminder2Checked = false;
      this.isCheckBoxReminder3Disable = true;
      this.isCheckBoxReminder3Checked = false;
      this.agree1 = false;
      this.reminderDate2 = "";
      this.reminder2Day = "";
    }
  }

  checkbox3Event(e) {
    //debugger;
    if (e.currentTarget.checked) {
      this.isCheckBoxReminder3Checked = true;
      // this.reminder3Day=20;
      // var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(this.reminder3Day, 'days');
      // this.reminderDate3 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
      if (this.reminder3Day > this.reminder2Day) {
        this.agree1 = false;
      }
      else {
        this.agree1 = true;
      }

    } else {
      this.isCheckBoxReminder3Checked = false;
      this.agree1 = false;
      this.reminderDate3 = "";
      this.reminder3Day = "";
    }
  }


  reminderDay(reminderType: any, reminderDay: any) {
    this.toast.show("Reminder : " + reminderType + " Reminder Days : " + reminderDay);
    //debugger;
    let days = parseInt(reminderDay);
    let addDays = 100 * 60 * 60 * 24 * days;
    if (reminderType == 1) {
      var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(days, 'days');
      this.reminderDate1 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
    }
    if (reminderType == 2) {
      if (this.reminder1Day < days) {
        this.agree1 = false;
        var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(days, 'days');
        this.reminderDate2 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
      }
      else {
        this.agree1 = true;
        this.toast.show("Please enter days greater than reminder 1");
      }
    }
    if (reminderType == 3) {
      if (this.reminder2Day < days) {
        this.agree1 = false;
        var new_date = moment(this.datepipe.transform(this.doa, 'dd/MM/yyyy'), "DD-MM-YYYY").add(days, 'days');
        this.reminderDate3 = this.datepipe.transform(new_date, 'dd/MM/yyyy');
      }
      else {
        this.agree1 = true;
        this.toast.show("Please enter days greater than reminder 2");
      }
    }
  }

  setStarttime() { }

  paymentModeOption() {

    let payableAmount = this.netPayableAmount;

    if (payableAmount > 0) {
      this.isPendingAmount = true;
      this.isCreditAmount = true;
      this.isAdvanceAmount = false;
      this.currentDueAmount = payableAmount;
    }
    else if (payableAmount < 0) {
      this.isPendingAmount = true;
      this.isCreditAmount = false;
      this.isAdvanceAmount = true;
      let advance = parseInt(this.advanceAmount);
      let netAmount = this.totalamount - this.discountAmount;
      let due_amount = parseInt(this.dueAmount);
      this.currentAdvanceAmount = advance - (netAmount + due_amount);
    }
    else {
      this.isPendingAmount = false;
      this.isCreditAmount = false;
      this.isAdvanceAmount = false;
    }
    if (this.paymentModeId === "1") {
      this.isCash = true;
      this.isCashOnline = false;
      this.isOnline = false;

    }
    else if (this.paymentModeId === "2") {
      this.isCash = false;
      this.isCashOnline = false;
      this.isOnline = true;
    }
    else if (this.paymentModeId === "3") {
      this.isCash = false;
      this.isCashOnline = true;
      this.isOnline = false;
    }
    else if (this.paymentModeId === "5") {
      this.isCash = false;
      this.isCashOnline = false;
      this.isOnline = false;
    }
  }

  //Methods for adding & removing services

  addService() {
    console.log("i am in add");
    let isDuplicateService = 0;
    if (this.ser != "" && this.cat != "") {
      this.validateData();
      if (this.allFieldRequired) {
        if (this.staffServiceList.length > 0) {

          this.getStaffDisplayNames();
          let staffListData = [];
          staffListData = this.staffServiceList;
          this.getTime();
          let temp = {
            Service_Id: this.ser,
            Amount: this.amount,
            Service_Name: this.subCategoryName,
            Service_Category_Name: this.categoryName,
            Service_Time: this.serviceTimeString,
            // Service_startTime: this.serviceStartTime,
            // Service_endTime: this.serviceStopTime,
            Start_Time: this.serviceStartTime,
            End_Time: this.serviceStopTime,
            Sequence_No: this.counter,
            Appointment_Date: this.datepipe.transform(this.doa, 'dd/MM/yyyy'),
            Staff_Name: this.displayStaff,
            Staff_List: staffListData
          };
          console.log(temp);
          if (this.servicetable.length != 0) {

            //Check is duplicate service
            // for (let serviceItem of this.servicetable) 
            // {
            //   if (serviceItem.Service_Id == temp.Service_Id) 
            //   {
            //     isDuplicateService = 1;
            //     break;
            //   }
            // }

            if (isDuplicateService == 0) {
              if (this.ser != "" && this.cat != "") {
                this.servicetable.push(temp);
                this.requestappointment.newBill.Bill_Service_List = [];
                this.requestappointment.newBill.Bill_Service_List = this.servicetable;
                this.calculateTotal();
                this.toast.show("Service Added");
                this.cat = "";
                this.ser = "";
                this.amount = 0;
                this.serviceTime = 0;
                this.counter++;
              }
            }
            else {
              this.toast.show("Service Already Added");
            }

          }
          else {

            this.servicetable.push(temp);
            this.requestappointment.newBill.Bill_Service_List = [];
            this.requestappointment.newBill.Bill_Service_List = this.servicetable;
            this.calculateTotal();
            this.toast.show("Service Added");
            this.cat = "";
            this.ser = "";
            this.amount = 0;
            this.serviceTime = 0;
            this.counter++;
          }
          temp = {
            Service_Id: "",
            Amount: 0,
            Service_Name: "",
            Service_Category_Name: "",
            Service_Time: "",
            Start_Time: "",
            End_Time: "",
            Sequence_No: 0,
            Appointment_Date: "",
            Staff_Name: "",
            Staff_List: []
          };
          this.staffServiceList = [];
          this.staff_id = 0;
        }
        else {
          this.toast.show("Please select staff for service")
        }
      } else {
        this.toast.show("All Field Required");
      }
    } else {
      this.toast.show("All Field Required");
    }
  }

  removeService(serviceItem) {
    for (let item in this.servicetable) {
      if (this.servicetable[item].Service_Name == serviceItem.Service_Name) {
        if (this.servicetable.length == 1) {
          this.requestappointment.newBill.Bill_Service_List = [];
          this.servicetable = [];
          this.calculateTotal();
        }
        else {
          this.servicetable.splice(parseInt(item), 1);
          // this.requestappointment.newBill.Bill_Service_List.slice(parseInt(s), 1)
          this.requestappointment.newBill.Bill_Service_List = [];
          this.requestappointment.newBill.Bill_Service_List = this.servicetable;
          this.calculateTotal();
        }
      }
    }
  }

  calculateTotal() {
    // debugger;
    this.totalamount = 0;
    this.discountAmount = 0;
    for (let st of this.servicetable) {
      this.totalamount = this.totalamount + parseFloat(st.Amount);
      this.discountAmount = this.discountAmount + parseFloat(st.DiscountAmount);
    }

    for (let productitem of this.productList) {
      this.totalamount = this.totalamount + parseFloat(productitem.Final_Rate);
      this.discountAmount = this.discountAmount + parseFloat(productitem.Discounted_Rate);
    }

    this.netPayableAmount = this.totalamount;
    this.calNet();
  }

  calNet() {

    //debugger;
    let amount = 0;
    let dummyAmount = this.totalamount;
    amount = dummyAmount - this.discountAmount
    amount = amount - parseInt(this.advanceAmount)
    amount = amount + parseInt(this.dueAmount)

    this.netPayableAmount = amount;
  }

  updateDueTotalAmount() {
    let amount = 0;
    let dueAmount_ = parseInt(this.dueAmount);
    amount = this.totalamount - this.discountAmount;
    amount = amount + dueAmount_;
    amount = amount - parseInt(this.advanceAmount);
    this.netPayableAmount = amount;
  }

  updateAdvanceTotalAmount() {
    let amount = 0;
    let dueAmount_ = parseInt(this.dueAmount);
    let advanceAmount_ = parseInt(this.advanceAmount);
    amount = this.totalamount - this.discountAmount;
    amount = amount + dueAmount_;
    amount = amount - advanceAmount_;
    this.netPayableAmount = amount;

  }

  calculateCreditAdvanceAmount(option: any) {
    let payableAmount = this.netPayableAmount;
    let paymentTotal = 0;
    if (this.isCash) {
      if (option === "Cash" || option === "Voucher") {
        paymentTotal = parseInt(this.cash) + parseInt(this.voucher);
        if (payableAmount != paymentTotal) {
          this.isPendingAmount = true;
          if (payableAmount > paymentTotal) {


            this.isCreditAmount = true;
            this.isAdvanceAmount = false;
            this.currentDueAmount = payableAmount - paymentTotal;
          }
          else if (payableAmount < paymentTotal) {

            this.isCreditAmount = false;
            this.isAdvanceAmount = true;
            this.currentAdvanceAmount = paymentTotal - payableAmount;
          }
          else {
            this.isCreditAmount = false;
            this.isAdvanceAmount = false;
          }
        }
        else {
          this.isPendingAmount = false;
        }

      }
    }
    else if (this.isOnline) {
      if (option === "GPay" || option === "PhonePay" || option === "Paytm" || option === "Other" || option === "Voucher") {
        paymentTotal = parseInt(this.paytm) + parseInt(this.gPay) + parseInt(this.phonePay) + parseInt(this.other) + parseInt(this.voucher);
        if (payableAmount != paymentTotal) {
          this.isPendingAmount = true;
          if (payableAmount > paymentTotal) {
            this.isCreditAmount = true;
            this.isAdvanceAmount = false;
            this.currentDueAmount = payableAmount - paymentTotal;
          }
          else if (payableAmount < paymentTotal) {
            this.isCreditAmount = false;
            this.isAdvanceAmount = true;
            this.currentAdvanceAmount = paymentTotal - payableAmount;
          }
          else {
            this.isCreditAmount = false;
            this.isAdvanceAmount = false;
          }
        }
        else {
          this.isPendingAmount = false;
        }

      }
    }
    else if (this.isCashOnline) {
      if (option === "Cash" || option === "GPay" || option === "PhonePay" || option === "Paytm" || option === "Other" || option === "Voucher") {

        paymentTotal = parseInt(this.cash) + parseInt(this.paytm) + parseInt(this.gPay) + parseInt(this.phonePay) + parseInt(this.other) + parseInt(this.voucher);
        if (payableAmount != paymentTotal) {
          this.isPendingAmount = true;
          if (payableAmount > paymentTotal) {
            this.isCreditAmount = true;
            this.isAdvanceAmount = false;
            this.currentDueAmount = payableAmount - paymentTotal;
          }
          else if (payableAmount < paymentTotal) {
            this.isCreditAmount = false;
            this.isAdvanceAmount = true;
            this.currentAdvanceAmount = paymentTotal - payableAmount;
          }
          else {
            this.isCreditAmount = false;
            this.isAdvanceAmount = false;
          }
        }
        else {
          this.isPendingAmount = false;
        }

      }
    }



  }

  getTime() {
    //debugger;
    let i = 0;
    let time_ = new Date(this.currentTime)
    let time_1 = new Date(this.currentTime)
    let startServiceTime = this.currentTime;
    let lastServiceTime = this.currentTime;

    console.log(this.currentTime);
    let arrayCount = this.servicetable.length;

    if (this.servicetable.length == 0 || this.servicetable == null) {
      lastServiceTime = time_.setMinutes(time_.getMinutes());
      time_1 = new Date(lastServiceTime)
      startServiceTime = time_1.setMinutes(time_1.getMinutes() - parseInt(this.serviceTime))

      this.serviceStartTime = this.datepipe.transform(startServiceTime, "hh:mm a");
      this.serviceStopTime = this.datepipe.transform(lastServiceTime, "hh:mm a");
    }
    for (let st of this.servicetable) {
      i++;
      if (arrayCount == 1) {
        lastServiceTime = st.Start_Time;
        startServiceTime = moment(lastServiceTime, "hh:mm a").subtract(parseInt(this.serviceTime), 'minutes').format('hh:mm a') // Subtract 30 mins
        this.serviceStartTime = startServiceTime;
        this.serviceStopTime = lastServiceTime;
      }
      else if ((arrayCount) == i) {
        lastServiceTime = st.Start_Time;
        startServiceTime = moment(lastServiceTime, "hh:mm a").subtract(parseInt(this.serviceTime), 'minutes').format('hh:mm a') // Subtract 30 mins
        this.serviceStartTime = startServiceTime;
        this.serviceStopTime = lastServiceTime;
      }
    }

  }

  getStaffDisplayNames() {
    let staffToService = "";
    if (this.staffServiceList.length > 0) {
      for (let item of this.staffServiceList) {
        if (staffToService === "") {
          staffToService = item.staffName;
        }
        else {
          staffToService = staffToService + "," + item.staffName;
        }
      }
    }
    this.displayStaff = staffToService;
  }

  //Methods For add & remove multiple staff to list
  addStaffToService() {
    //debugger;
    for (let s of this.staffList) {
      if (s.Staff_Id == this.staff_id) {

        let temp = {
          staffId: s.Staff_Id,
          staffName: s.Staff_Name
        }
        this.staffServiceList.push(temp)
      }
    }
  }

  removeStaff(staffItem) {
    for (let item of this.staffServiceList) {
      if (item.staffId === staffItem.staffId) {
        let deletedIndex = this.staffServiceList.indexOf(staffItem);
        this.staffServiceList.splice(this.staffServiceList.indexOf(staffItem), 1);
      }
    }
  }

  //Bill entry method

  clearData() {
    this.clientName = this.clientByMobileResponse.getClientByMobileNoResult.Client_Name;
    this.lastVisitedDate = "-";
    this.lastBillAmount = "-";

    this.totalCollection = "-";
    this.numberOfVisit = "-";
    this.daysOfLastVisit = "-";
    this.requestappointment.newBill.Client_Id = this.clientByMobileResponse.getClientByMobileNoResult.Client_Id;
    this.requestBillEntry.newBill.Client_Id = this.clientByMobileResponse.getClientByMobileNoResult.Client_Id;
    this.age = "-";
    this.city = "-";
    this.clientZone = "-";

    this.isGreen = false;
    this.isRed = false;
    this.isYellow = false;
    this.isGray = false;
    this.dueAmount = "0";
    this.advanceAmount = "0"
  }



  //New Logic methods

  getAllCategory() {
    //debugger;
    this.categoryDDL = [];
    console.log("AllCategory Request Objecct");
    console.log(this.categoryDDLRequest);
    this.rest.getServiceCategory(this.categoryDDLRequest).then((data) => {
       //debugger;
      this.categoryDDLResponse = data;
      this.categoryDDL = this.categoryDDLResponse.getServiceCategoryList2Result;
      for (let item of this.categoryDDL) {
          let oldItemData=item;
           oldItemData.Discount_Percent=item.Discount_Percent==null?this.parlorDiscountPer:item.Discount_Percent;
           oldItemData.GSTPercent=item.GSTPercent==null?this.parlorGSTPercent:item.GSTPercent;
           oldItemData.GSTType=item.GSTType==null?this.parlorGSTType:item.GSTType;

           this.categoryDDL.splice(parseInt(item), 1);
           this.categoryDDL.push(oldItemData);
    
           
        }
      
      console.log("service");
      console.log(this.categoryDDL);
    });
  }

  getStaffList() {
    // debugger;
    console.log("Staff Request");
    console.log(this.staffRequest);
    this.rest.getStafflistDDL(this.staffRequest).then((data) => {
      // this.load.present1();
      //debugger;
      console.log(data);
      this.staffResponse = data;
      this.staffList = this.staffResponse.getStaffDDLResult;
    });
  }

  getStaffDisplayNames_(staffList_: any) {
    let staffToService = "";
    if (staffList_.length > 0) {
      for (let item of staffList_) {
        if (staffToService === "") {
          staffToService = item.Staff_Name;
        }
        else {
          staffToService = staffToService + "," + item.Staff_Name;
        }
      }
    }
    this.displayStaff = staffToService;
  }

  getTime_() {
    //debugger;
    let i = 0;
    let time_ = new Date(this.currentTime)
    let time_1 = new Date(this.currentTime)
    let startServiceTime = this.currentTime;
    let lastServiceTime = this.currentTime;

    console.log(this.currentTime);
    let arrayCount = this.servicetable.length;

    if (this.servicetable.length == 0 || this.servicetable == null) {
      lastServiceTime = time_.setMinutes(time_.getMinutes());
      time_1 = new Date(lastServiceTime)
      startServiceTime = time_1.setMinutes(time_1.getMinutes() - parseInt(this.serviceTime))

      this.serviceStartTime = this.datepipe.transform(startServiceTime, "hh:mm a");
      this.serviceStopTime = this.datepipe.transform(lastServiceTime, "hh:mm a");
    }
    for (let st of this.servicetable) {
      i++;
      if (arrayCount == 1) {
        lastServiceTime = st.Start_Time;
        startServiceTime = moment(lastServiceTime, "hh:mm a").subtract(parseInt(this.serviceTime), 'minutes').format('hh:mm a') // Subtract 30 mins
        this.serviceStartTime = startServiceTime;
        this.serviceStopTime = lastServiceTime;
      }
      else if ((arrayCount) == i) {
        lastServiceTime = st.Start_Time;
        startServiceTime = moment(lastServiceTime, "hh:mm a").subtract(parseInt(this.serviceTime), 'minutes').format('hh:mm a') // Subtract 30 mins
        this.serviceStartTime = startServiceTime;
        this.serviceStopTime = lastServiceTime;
      }
    }

  }

  //GST Billing
  getClientListSearch() {

    if (this.isPopupShow) {
      this.isItemAvailable = false;
      this.isPopupShow = false;
    }
    else {
      if (this.isClear) {
        this.isClear = false;
        this.getClientListSearch();
      }
      else if (this.isSelectedItem) {
        this.isSelectedItem = false;
        this.getClientListSearch();
      }
      else {

        this.isClear = false;
        this.isSelectedItem = false;
        if (this.searchRequest.searchText.length >= 3) {
          //debugger;
          this.rest.getSearchResult2(this.searchRequest).then((data) => {
            console.log(data);

            this.searchResponse = data;
            if (this.searchResponse.getClientListSearch2Result.length > 0) {
              this.isItemAvailable = true;
              this.items = this.searchResponse.getClientListSearch2Result;
            } else {
              // this.client==[];

              this.isItemAvailable = false;
              if (this.searchRequest.searchText.length == 10) {
                var NUMBER_REGEX = "^((\\+91-?)|0)?[0-9]{10}$";
                if (this.searchRequest.searchText.match(NUMBER_REGEX) && !this.isPopupShow) {
                  this.isPopupShow = true;

                  this.createClient();
                }
              }
              //  this.getlients();
            }
          });
        }
        else if (this.searchRequest.searchText.length < 3) {
          this.isItemAvailable = false;
        }
      }

    }
  }

  clear() {
    if (this.searchRequest.searchText != "") {
      this.searchRequest.searchText = "";
      this.isClear = false;
      this.isItemAvailable = false;
    }
    else if (this.searchRequest.searchText == "") {
      this.isItemAvailable = false;
    }
  }

  selectsearch(item) {
    console.log(item);
    this.clientByMobileRequest.Mobile_No = item.Mobile_No;
    this.searchRequest.searchText = "";
    this.isItemAvailable = false;
    this.isSelectedItem = true;
  }

  showServices() {
    //debugger;
    this.categoryServiceArray = [];
    console.log("Selected Category :: ");
    for (let categoryItem of this.selectedCategory) {
      let result = this.categoryDDL.filter(e => {
        return parseInt(categoryItem) == parseInt(e.Service_Catagory_ID);
      })

      let counter = 0;
      for (let serviceItem of result[0].Service_List) {
        if (serviceItem.Is_Selected) {
          result[0].Service_List[counter].Is_Selected = false;
        }
        counter++;
      }

      this.categoryServiceArray.push(result)
    }
    console.log(this.categoryServiceArray);
  }

  allowMultipleStaff(event) {
    this.staff_id = 0;
    let isChecked = event.target.checked;
    if (isChecked) {
      this.isSingleStaffSelected = false;
      this.isMultipleStaffSelected = true;
    }
    else {
      this.isSingleStaffSelected = true;
      this.isMultipleStaffSelected = false;
    }
  }

  allowSingleStaff(event) {
    this.staff_id = 0;
    let isChecked = event.target.checked;
    if (isChecked) {
      this.singleStaffToAllService = true;
      this.isMultipleStaffSelected = false;
    }
    else {
      this.singleStaffToAllService = false;
      this.isMultipleStaffSelected = true;
    }
  }

  async checkServiceSelect(event, item) {
    //debugger;
    let isChecked = event.target.checked;
    let isDuplicate = false;
    console.log("checked Item ::");
    console.log(item);

    if (this.serviceCategoryWithCheck.indexOf(item.Service_Name) > -1) {
      isDuplicate = true;
    }
    if (!this.singleStaffToAllService) {
      if (isChecked) {
        if (!isDuplicate) {
          this.serviceCategoryWithCheck.push(item.Service_Name);

          this.storage.set('serviceItem', item);
          const modal = await this.modalController.create({
            component: ServiceStaffAssignPopupPage,
            cssClass: 'success-modal'
          });

          modal.onDidDismiss().then(modelData => {
            //debugger;
            console.log("Popup return data");
            console.log(modelData.data);
            let staffArray = modelData.data['selectedStaff'];
            let changedServiceAmount = modelData.data['serviceAmount'];
            if (staffArray.length > 0) {
              let discountAmount_=((parseInt(item.Service_Rate) * parseInt(item.Discount_Percent)) / 100);
              let discountedAmount_=parseInt(item.Service_Rate) - discountAmount_;
              let tempItem = {
                serviceCategoryId: item.Service_Catagory_ID,
                serviceCategoryName: item.Service_Category_Name,
                serviceId: item.Service_ID,
                serviceName: item.Service_Name,
                serviceAmount: parseInt(item.Service_Rate),
                serviceTime: item.Service_Time,
                serviceTimeString: item.Service_Time_String,
                staffList: staffArray,
                discount: parseInt(item.Discount_Percent),
                discountAmount: discountAmount_,
                discountRate: discountedAmount_,
                basicRate: this.getBasicRate(item.GSTType, discountedAmount_, item.GSTPercent),
                gst: parseInt(item.GSTPercent),
                gstType: item.GSTType
              }

              this.selectedServiceStaffData.push(tempItem);

              console.log("------ selectedServiceStaffData -------");
              console.log(this.selectedServiceStaffData);
            }
            else {
              item.isCheck = false;
              this.toast.show("Please select staff from popup");
            }
          });
          return await modal.present();
        }

        else {
          isDuplicate = false;
        }
      }
      else {
        let index = this.serviceCategoryWithCheck.indexOf(item.Service_Name);

        if (index > -1) {
          this.serviceCategoryWithCheck.splice(index, 1);
        }

        let j = 0;
        for (let serviceStaffItem of this.selectedServiceStaffData) {
          if (serviceStaffItem.serviceName === item.Service_Name) {
            this.selectedServiceStaffData.splice(j, 1);
            break;
          }
          j++;
        }
      }
    }
    else {
      if (isChecked) {
        if (!isDuplicate) {
          this.serviceCategoryWithCheck.push(item.Service_Name);
          let result = this.staffList.filter(e => {
            return this.staff_id == parseInt(e.Staff_Id);

          })
          let staffArray: any[] = [];
          let tempStaff = {
            Staff_Name: result[0].Staff_Name,
            Staff_Id: result[0].Staff_Id,
            isCheck: false
          };
          staffArray.push(tempStaff)
          let discountAmount_=((parseInt(item.Service_Rate) * parseInt(item.Discount_Percent)) / 100);
          let discountedAmount_=parseInt(item.Service_Rate) - discountAmount_;

          let tempItem = {
            serviceCategoryId: item.Service_Catagory_ID,
            serviceCategoryName: item.Service_Category_Name,
            serviceId: item.Service_ID,
            serviceName: item.Service_Name,
            serviceAmount: parseInt(item.Service_Rate),
            serviceTime: item.Service_Time,
            serviceTimeString: item.Service_Time_String,
            staffList: staffArray,
            discount: parseInt(item.Discount),
            discountAmount: discountAmount_,
            discountRate: discountedAmount_,
            basicRate: this.getBasicRate(item.GSTType,discountedAmount_, item.GSTPercent),
            gst: parseInt(item.GSTPercent),
            gstType: item.GSTType
          }

          this.selectedServiceStaffData.push(tempItem);

          console.log("------ selectedServiceStaffData -------");
          console.log(this.selectedServiceStaffData);
        }
        else {
          isDuplicate = false;
        }
      }
      else {
        let index = this.serviceCategoryWithCheck.indexOf(item.Service_Name);

        if (index > -1) {
          this.serviceCategoryWithCheck.splice(index, 1);


          let j = 0;
          for (let serviceStaffItem of this.selectedServiceStaffData) {
            if (serviceStaffItem.serviceName === item.Service_Name) {
              this.selectedServiceStaffData.splice(j, 1);
              break;
            }
            j++;
          }
        }
      }
    }
  }

  
  checkServiceSelect_Amount(item) {
    let oldData: any;
    let j = 0;
    for (let serviceStaffItem of this.selectedServiceStaffData) {
      if (serviceStaffItem.serviceName === item.Service_Name) {
        oldData = serviceStaffItem;
        oldData.serviceAmount = parseInt(item.Service_Rate);
        this.selectedServiceStaffData.splice(j, 1);
        this.selectedServiceStaffData.push(oldData);
        break;
      }
      j++;
    }
  }

  setServiceDiscountAmount(item) {
    let oldData: any;
    let j = 0;
    for (let serviceStaffItem of this.selectedServiceStaffData) {
      if (serviceStaffItem.serviceName === item.Service_Name) {
        oldData = serviceStaffItem;
        oldData.Discount = parseInt(item.Discount);
        this.selectedServiceStaffData.splice(j, 1);
        this.selectedServiceStaffData.push(oldData);
        break;
      }
      j++;
    }
  }

  checkServiceSelect_GST(item) {
    //debugger;
    let oldData: any;
    let j = 0;
    for (let serviceStaffItem of this.selectedServiceStaffData) {
      if (serviceStaffItem.serviceName === item.Service_Name) //match data from list
      {
        oldData = serviceStaffItem;//take old data item
        oldData.GSTPercent = parseInt(item.GSTPercent);//update with new valus
        this.selectedServiceStaffData.splice(j, 1);//remove that item
        this.selectedServiceStaffData.push(oldData);//add updated item
        break;
      }
      j++;
    }
  }

  addService_new() {
     debugger;
    console.log("i am in add");
    let isDuplicateService = 0;
    this.validateData();
    if (this.allFieldRequired) {
      if (this.selectedServiceStaffData.length > 0) {
        for (let serviceItem of this.selectedServiceStaffData) {
          this.getStaffDisplayNames_(serviceItem.staffList);
          let staffListData = [];
          staffListData = serviceItem.staffList;
          this.serviceTime = serviceItem.serviceTime;
          this.getTime_();
          let temp =
          {
            Service_Id: serviceItem.serviceId,
            Amount: serviceItem.serviceAmount,
            Service_Name: serviceItem.serviceName,
            Service_Category_Name: serviceItem.serviceCategoryName,
            Service_Time: serviceItem.serviceTimeString,
            Start_Time: this.serviceStartTime,
            End_Time: this.serviceStopTime,
            Sequence_No: this.counter,
            Appointment_Date: this.datepipe.transform(this.doa, 'dd/MM/yyyy'),
            Staff_Name: this.displayStaff,
            Staff_List: staffListData,
            Discount: serviceItem.discount,
            DiscountAmount: serviceItem.discountAmount,
            DiscountedRate: serviceItem.discountRate,
            GSTType: serviceItem.gstType,
            GSTPercent: serviceItem.gst,
            BasicRate: serviceItem.basicRate,
            GSTAmount: this.getGSTAmount(serviceItem.basicRate, serviceItem.gst).toFixed(2),
            ServiceRate: this.getFinalServiceAmount(serviceItem.basicRate, serviceItem.gst).toFixed(2)

          };
          console.log(temp);
          if (this.servicetable.length != 0) {
            this.servicetable.push(temp);
            this.requestappointment.newBill.Bill_Service_List = [];
            this.requestappointment.newBill.Bill_Service_List = this.servicetable;
            this.calculateServiceTotal();
            this.toast.show("Service Added");
            this.cat = "";
            this.ser = "";
            this.amount = 0;
            this.serviceTime = 0;
            this.counter++;
          }
          else {
            this.servicetable.push(temp);
            this.requestappointment.newBill.Bill_Service_List = [];
            this.requestappointment.newBill.Bill_Service_List = this.servicetable;
            this.calculateServiceTotal();
            this.toast.show("Service Added");
            this.cat = "";
            this.ser = "";
            this.amount = 0;
            this.serviceTime = 0;
            this.counter++;
          }
          temp =
          {
            Service_Id: "",
            Amount: 0,
            Service_Name: "",
            Service_Category_Name: "",
            Service_Time: "",
            Start_Time: "",
            End_Time: "",
            Sequence_No: 0,
            Appointment_Date: "",
            Staff_Name: "",
            Staff_List: [],
            Discount: "",
            DiscountAmount: 0,
            DiscountedRate: 0,
            GSTType: "",
            GSTPercent: "",
            BasicRate: "",
            GSTAmount: "",
            ServiceRate: 0

          };
          this.staffServiceList = [];
          this.staff_id = 0;
          this.selectedServiceStaffData = [];
        }

        this.selectedCategory = [];
        this.categoryServiceArray = [];
        this.isSingleStaffSelected = false;
        this.staff_id = 0;
        this.getAllCategory();
      }
      else {
        this.toast.show("Please select service");
      }
    }
    else {
      this.toast.show("All Field Required");
    }

  }

  getBasicRate(gstType, discountaRate, gstPercentage) {
    let gstPer;
    if(gstPercentage>=10)
    {
      gstPer = "1." + gstPercentage;
    }
    else
    {
      gstPer = "1.0" + gstPercentage;
    }
    
    if (gstType == "Include") {
      this.returnBasicRate = discountaRate / parseFloat(gstPer);
    }
    else {
      this.returnBasicRate = discountaRate
    }
    return this.returnBasicRate;
  }

  getGSTAmount(basicRate, gstPercentage) {
    return (basicRate * gstPercentage) / 100;
  }
  getFinalServiceAmount(basicRate, gstPercentage,) {
    return basicRate + (basicRate * gstPercentage) / 100;
  }


  selectService() {
    this.IsService = true;
  }

  selectProduct() {
    this.IsService = false;
  }

  getCompanyList() {
    //debugger;
    this.companyDataList = [];
    this.rest
      .getProductCompany_(this.getCompanyDDLRequest)
      .then((data) => {
        // debugger;
        this.companyData = data;
        this.companyDataList = this.companyData.getProductCompanyDDLResult;
        console.log("Company List :");
        console.log(this.companyDataList);
      });
  }

  showProducts() {
    //debugger;
    this.companyProductArray = [];
    console.log("Selected Companys :: ");
    console.log(this.selectedCompany);

    console.log("Selected Category :: ");
    for (let companyId of this.selectedCompany) {
      let result = this.companyDataList.filter(e => {
        return parseInt(companyId) == parseInt(e.Company_Id);
      })

      let counter = 0;
      for (let serviceItem of result[0].ProductList) {
        if (serviceItem.Is_Selected) {
          result[0].ProductList[counter].Is_Selected = false;
        }
        if (serviceItem.GSTType == null) {
          result[0].ProductList[counter].GSTType = result[0].GSTType;
          result[0].ProductList[counter].GSTPercent = result[0].GSTPercent;
        }
        if (serviceItem.Discount_Percent == '0') {
          result[0].ProductList[counter].Discount_Percent = result[0].Discount_Percent;
        }

        counter++;
      }

      this.companyProductArray.push(result)
    }
    //debugger;
    console.log(this.companyProductArray);
  }

  async checkSelectProduct(event, item) {
    //debugger;
    let isChecked = event.target.checked;
    console.log("checked Item ::");
    console.log(item);
    if (isChecked) {
      let _discountAmount = ((parseInt(item.Product_Rate) * parseInt(item.Discount_Percent)) / 100);
      let _discountedAmount = parseInt(item.Product_Rate) - _discountAmount;
      let _basicRate = this.getBasicRate(item.GSTType, _discountedAmount, item.GSTPercent)
      let tempItem = {
        companyId: item.Company_Id,
        companyName: item.Company_Name,
        productId: item.Product_Id,
        productname: item.Pack_Name,
        productAmount: parseInt(item.Product_Rate),
        discount: parseInt(item.Discount_Percent),
        discountAmount: _discountAmount,
        discountRate: _discountedAmount,
        basicRate: _basicRate,
        gst: parseInt(item.GSTPercent),
        gstType: item.GSTType,
        ExpiryDate: "",
        Quantity: 1,
        PackId: item.Pack_Id
      }
      this.selectedProductData.push(tempItem);
    }
    else {
      let j = 0;
      for (let productItem of this.selectedProductData) {
        if (productItem.productname === item.Pack_Name) {
          this.selectedProductData.splice(j, 1);
          break;
        }
        j++;
      }
    }

  }

  checkSelectProduct_GST(item) {
    //debugger;
    let oldData: any;
    let j = 0;
    for (let productItem of this.selectedProductData) {
      if (productItem.productname === item.Pack_Name) //match data from list
      {
        oldData = productItem;//take old data item
        oldData.GSTPercent = parseInt(item.GSTPercent);//update with new valus
        this.selectedProductData.splice(j, 1);//remove that item
        this.selectedProductData.push(oldData);//add updated item
        break;
      }
      j++;
    }
  }

  checkSelectProduct_Amount(item) {
    let oldData: any;
    let j = 0;
    for (let productItem of this.selectedProductData) {
      if (productItem.productname === item.Pack_Name) {
        oldData = productItem;
        oldData.productAmount = parseInt(item.Product_Rate);
        this.selectedProductData.splice(j, 1);
        this.selectedProductData.push(oldData);
        break;
      }
      j++;
    }
  }

  setProductDiscountAmount(item) {
    let oldData: any;
    let j = 0;
    for (let productItem of this.selectedProductData) {
      if (productItem.productname === item.Pack_Name) {
        oldData = productItem;
        oldData.Discount = parseInt(item.Discount);
        this.selectedProductData.splice(j, 1);
        this.selectedProductData.push(oldData);
        break;
      }
      j++;
    }
  }

  addProduct() 
  {
    debugger;
    console.log("i am in add");
    let isDuplicateService = 0;
    this.validateData();
    if (this.allFieldRequired) {
      if (this.selectedProductData.length > 0) {

        for (let productItem of this.selectedProductData) {

          let _gstAmount = this.getGSTAmount(productItem.basicRate, productItem.gst).toFixed(2)
          let temp =
          {
            companyName: productItem.companyName,
            productname: productItem.productname,
            Product_Id: productItem.productId,
            Pack_Id: productItem.PackId,//NI
            Expiry_Date: productItem.ExpiryDate,
            Quantity: productItem.Quantity,//NI
            MRP: productItem.productAmount,
            Discount_Percent: productItem.discount,
            DiscountAmount: productItem.discountAmount,
            Discounted_Rate: productItem.discountRate.toFixed(2),
            Basic_Rate: productItem.basicRate.toFixed(2),
            Final_Rate: (parseFloat(productItem.basicRate) + parseFloat(_gstAmount)).toFixed(2),//Ni
            Amount: _gstAmount,//GST amount
            GST_Percent: productItem.gst,
            SGST_Amount: parseFloat(_gstAmount) / 2,
            CGST_Amount: parseFloat(_gstAmount) / 2,
            IGST_Amount: 0,
            gstType: productItem.gstType
          };
          console.log(temp);
          if (this.productTable.length != 0) {
            this.productTable.push(temp);
            this.calculateProductTotal();
            this.toast.show("Service Added");

            this.counter++;
          }
          else {
            this.productTable.push(temp);
            this.calculateProductTotal();
            this.toast.show("Service Added");

            this.counter++;
          }
          temp =
          {
            companyName: "",
            productname: "",
            Product_Id: 0,
            Pack_Id: 0,
            Expiry_Date: "",
            Quantity: 0,//NI
            MRP: 0,
            Discount_Percent: 0,
            DiscountAmount: 0,
            Discounted_Rate: 0,
            Basic_Rate: 0,
            Final_Rate: "0.0",//Ni
            Amount: "0",//GST amount
            GST_Percent: 0,
            SGST_Amount: 0,
            CGST_Amount: 0,
            IGST_Amount: 0,
            gstType: ""

          };
          this.selectedProductData = [];
        }
        this.companyProductArray = [];
        this.getCompanyList();
      }
      else {
        this.toast.show("Please select service");
      }
    }
    else {
      this.toast.show("All Field Required");
    }

  }

  removeProduct(productItem) {
    for (let item in this.productTable) {
      if (this.productTable[item].productname == productItem.productname) {
        if (this.productTable.length == 1) {
          this.productTable = [];
          this.calculateProductTotal();
        }
        else {
          this.productTable.splice(parseInt(item), 1);
          // this.requestappointment.newBill.Bill_Service_List.slice(parseInt(s), 1)
          this.calculateProductTotal();
        }
      }
    }
  }

  


  async billEntry() {
    //this.getAllServiceCat();
    // debugger;
    this.agree1 = true;
    //this.showButton=false;
    if (this.comefrom == 1) {
      this.requestBillEntry.newBill.Bill_Date = this.datepipe.transform(this.doa, "dd/MM/yyyy");
    }
    else {
      this.requestBillEntry.newBill.Bill_Date = this.datepipe.transform(this.doa, "dd/MM/yyyy");
    }

    this.requestBillEntry.newBill.Total_Amount = this.totalamount;
    this.requestBillEntry.newBill.Discount_Amount = this.discountAmount;
    this.requestBillEntry.newBill.Net_Amount = this.totalamount - this.discountAmount;
    this.requestBillEntry.newBill.Mobile_No = this.clientByMobileRequest.Mobile_No;

    if (this.productList.length > 0 && this.servicetable.length > 0) {
      this.requestBillEntry.newBill.Bill_Type = "SP";
    }
    else if (this.productList.length > 0) {
      this.requestBillEntry.newBill.Bill_Type = "P";
    }
    else if (this.servicetable.length > 0) {
      this.requestBillEntry.newBill.Bill_Type = "S";
    }




    this.validateData();
    if (this.allFieldRequired) {
      //debugger;
      if (
        this.requestBillEntry.newBill.Bill_Date != "" &&
        this.requestBillEntry.newBill.Discount_Amount >= 0) {
        if (this.requestappointment.newBill.Bill_Service_List.length > 0) {
          if (this.paymentModeId != "") {

            this.load = await this.loader.create({
              spinner: 'bubbles',
              message: 'wait a moment'
            })
            this.load.present();

            if (this.billReminderCount == 1) {
              if (this.isCheckBoxReminder1Checked) {
                var temp = {
                  Bill_Remainder_Name: "R1",
                  Bill_Remainder_Date: this.reminderDate1
                }
                this.requestBillEntry.newBill.Bill_Remainder_List.push(temp);
              }
            }
            else if (this.billReminderCount == 2) {
              if (this.isCheckBoxReminder1Checked) {
                var temp = {
                  Bill_Remainder_Name: "R1",
                  Bill_Remainder_Date: this.reminderDate1
                }
                this.requestBillEntry.newBill.Bill_Remainder_List.push(temp);
              }
              if (this.isCheckBoxReminder1Checked && this.isCheckBoxReminder2Checked) {
                var temp2 = {
                  Bill_Remainder_Name: "R2",
                  Bill_Remainder_Date: this.reminderDate2
                }
                this.requestBillEntry.newBill.Bill_Remainder_List.push(temp2);
              }
              else {
                if (this.reminder1Day < this.reminder2Day) {
                  this.toast.show("Please enter days grater than reminder 1");
                  return;
                }
              }
            }
            else if (this.billReminderCount == 3) {
              if (this.isCheckBoxReminder1Checked) {
                var temp = {
                  Bill_Remainder_Name: "R1",
                  Bill_Remainder_Date: this.reminderDate1
                  //Bill_Remainder_Date: this.datepipe.transform(this.reminderDate1, "dd/MM/yyyy")
                }
                this.requestBillEntry.newBill.Bill_Remainder_List.push(temp);
              }
              if (this.isCheckBoxReminder1Checked && this.isCheckBoxReminder2Checked) {
                var temp2 = {
                  Bill_Remainder_Name: "R2",
                  Bill_Remainder_Date: this.reminderDate2
                  //Bill_Remainder_Date: this.datepipe.transform(this.reminderDate2, "dd/MM/yyyy")
                }
                this.requestBillEntry.newBill.Bill_Remainder_List.push(temp2);
              }
              if (this.isCheckBoxReminder1Checked && this.isCheckBoxReminder2Checked && this.isCheckBoxReminder3Checked) {
                var temp3 = {
                  Bill_Remainder_Name: "R3",
                  //Bill_Remainder_Date: this.datepipe.transform(this.reminderDate3, "dd/MM/yyyy")
                  Bill_Remainder_Date: this.reminderDate3
                }
                this.requestBillEntry.newBill.Bill_Remainder_List.push(temp3);
              }

            }

            //create service array
            let staffArray = [];
            let serviceArray = [];
            let paymentModeList = [];
            let creditAmount = 0;
            let advanceAmount = 0;
            let _dueAmount = 0;
            let _advanceAmount = 0;
            let payAmount = 0;

            for (let serviceItem of this.servicetable) {
              // Bill_Service_List{Service_Id, Amount, Sequence_No, Quantity, MRP, Discount_Percent, Discounted_Rate, 
              //Basic_Rate, Final_Rate, GST_Percent,SGST_Amount, CGST_Amount, IGST_Amount}
              let tempServiceItem =
              {
                Staff_Id: 0,
                Amount: serviceItem.Amount,
                Sequence_No: serviceItem.Sequence_No,
                Quantity: 0,
                MRP: 0,
                Discount_Percent: 0,
                Discounted_Rate: 0,
                Basic_Rate: 0,
                Final_Rate: 0,
                GST_Percent: 0,
                SGST_Amount: 0,
                CGST_Amount: 0,
                IGST_Amount: 0,
                Service_Category_Name: serviceItem.Service_Category_Name,
                Service_Id: serviceItem.Service_Id,
                Service_Name: serviceItem.Service_Name,
                Start_Time: serviceItem.Start_Time,
                End_Time: serviceItem.End_Time,
              }
              serviceArray.push(tempServiceItem)

              let staffListArray = [];
              staffListArray = serviceItem.Staff_Name.split(',');
              if (staffListArray.length > 0) {
                for (let st of staffListArray) {
                  let index = this.staffList.findIndex(item => item.Staff_Name === st);
                  if (index >= 0) {
                    let staffItem = this.staffList[index];
                    console.log(staffItem);
                    // Bill_Staff_List{Staff_Id, Service_Id, Amount, Bill_Date, Start_Time, End_Time, Sequence_No}
                    let tempStaffItem =
                    {
                      Staff_Id: this.staffList[index].Staff_Id,
                      Service_Id: serviceItem.Service_Id,
                      Amount: serviceItem.Amount,
                      Bill_Date: this.datepipe.transform(this.doa, "dd/MM/yyyy"),
                      Start_Time: serviceItem.Start_Time,
                      End_Time: serviceItem.End_Time,
                      Sequence_No: serviceItem.Sequence_No
                    }
                    staffArray.push(tempStaffItem)
                  }
                }
              }

            }

            let paymentModeArray = ['cash', 'voucher', 'gPay', 'phonePay', 'paytm', 'other']

            if (this.isCash) {
              let i = 0;
              for (let payoutitemData of paymentModeArray) {
                if (i <= 1) {
                  let payoutItem =
                  {
                    Payment_Mode: paymentModeArray[i],
                    Transaction_Amount: paymentModeArray[i] === 'cash' ? this.cash : this.voucher
                  }
                  payAmount = payAmount + parseInt(payoutItem.Transaction_Amount)
                  paymentModeList.push(payoutItem);
                }
                i++;
              }
            }
            else if (this.isOnline) {
              let j = 0;
              for (let payoutitemData of paymentModeArray) {
                if (j >= 1) {
                  let payoutItem =
                  {
                    Payment_Mode: paymentModeArray[j],
                    Transaction_Amount: paymentModeArray[j] === 'gPay' ? this.gPay : paymentModeArray[j] === 'phonePay' ? this.phonePay : paymentModeArray[j] === 'paytm' ? this.paytm : paymentModeArray[j] === 'other' ? this.other : this.voucher
                  }
                  payAmount = payAmount + parseInt(payoutItem.Transaction_Amount)
                  paymentModeList.push(payoutItem);
                }
                j++;
              }
            }
            else if (this.isCashOnline) {
              let k = 0;
              for (let payoutitemData of paymentModeArray) {
                let payoutItem =
                {
                  Payment_Mode: paymentModeArray[k],
                  Transaction_Amount: paymentModeArray[k] === 'cash' ? this.cash : paymentModeArray[k] === 'gPay' ? this.gPay : paymentModeArray[k] === 'phonePay' ? this.phonePay : paymentModeArray[k] === 'paytm' ? this.paytm : paymentModeArray[k] === 'other' ? this.other : this.voucher
                }
                payAmount = payAmount + parseInt(payoutItem.Transaction_Amount)
                paymentModeList.push(payoutItem);
                k++;
              }

            }

            // debugger;
            if (this.netPayableAmount > payAmount) {
              creditAmount = this.netPayableAmount - payAmount;
              this.requestBillEntry.newBill.Due_Amount = this.dueAmount;
              this.requestBillEntry.newBill.Used_Advance = this.advanceAmount;
            }
            else if (this.netPayableAmount < payAmount) {
              advanceAmount = payAmount - this.netPayableAmount;
              this.requestBillEntry.newBill.Due_Amount = this.dueAmount;
              this.requestBillEntry.newBill.Used_Advance = this.advanceAmount;
            }
            else if (this.netPayableAmount == payAmount) {
              this.requestBillEntry.newBill.Due_Amount = this.dueAmount;
              this.requestBillEntry.newBill.Used_Advance = this.advanceAmount;
            }

            this.requestBillEntry.newBill.Bill_Service_List = serviceArray;
            this.requestBillEntry.newBill.Bill_Staff_List = staffArray;
            this.requestBillEntry.newBill.Bill_Product_List = this.productList;
            this.requestBillEntry.newBill.Client_Payment_Mode_List = paymentModeList;
            this.requestBillEntry.newBill.Payment_Mode_Id = this.paymentModeId,
              this.requestBillEntry.newBill.Remark = this.remarkContent,
              this.requestBillEntry.newBill.Credit_Amount = creditAmount,
              this.requestBillEntry.newBill.Advance_Amount = advanceAmount,

              console.log(this.requestBillEntry);

            this.rest.billEntryGST(this.requestBillEntry).then((data) => {
              console.log(data);
              this.billEntryResponse = data;

              if (this.billEntryResponse.SaveBill3Result.Result == "Success") {
                this.agree1 = false;
                this.clientByMobileRequest.Mobile_No = "";
                this.clientName = "";
                this.doa = "";
                this.servicetable = [];
                this.requestBillEntry.newBill.Discount_Amount = 0;
                this.requestBillEntry.newBill.Net_Amount = 0;
                this.load.dismiss();
                this.toast.show("Bill Added successfully");
                this.counter = 1;
                this.totalamount = 0;
                //this.showButton=true;
                this.clearData();
                if (this.isFromAppointment) {
                  this.navCtrl.setDirection("root");
                  this.navCtrl.navigateRoot("/tabs/home");
                }
                else {
                  this.navCtrl.setDirection("root");
                  // this.navCtrl.navigateRoot("");
                  this.navCtrl.back();
                  // this.navCtrl.navigateRoot("/tabs/home");
                }

              } else {
                this.agree1 = false;
                //this.showButton=true;
                this.load.dismiss();
                this.toast.show("Bill Not Added");
              }
            });
          } else {
            this.toast.show("Please Select Payment Mode");
            this.agree1 = false;
            //this.showButton=true;
          }
        } else {
          this.toast.show("Please Select service");
          this.agree1 = false;
          //this.showButton=true;
        }
      } else {
        this.toast.show("All Field Require");
        this.agree1 = false;
        //this.showButton=true;
      }
    } else {
      this.toast.show("All Field Require");
      this.agree1 = false;
      //this.showButton=true;
    }
  }


  calculateServiceTotal() {
     let sBillingAmount=0;
     let sDiscountAmount=0;
     let sTaxableAmount=0;
     let sTaxAmount=0;

    
    for (let st of this.servicetable) {
      sBillingAmount = sBillingAmount + parseFloat(st.Amount);
      sDiscountAmount = sDiscountAmount + parseFloat(st.DiscountAmount);
      sTaxableAmount = sTaxableAmount + parseFloat(st.BasicRate);
      sTaxAmount = sTaxAmount + parseFloat(st.GSTAmount);
    }

    this.serviceBillingAmount = sBillingAmount;
    this.serviceDiscountAmount = sDiscountAmount;
    this.serviceTaxableAmount = sTaxableAmount.toFixed(2);
    this.serviceTaxAmount = sTaxAmount.toFixed(2);
    this.servicePaybalAmount = parseFloat(this.serviceTaxableAmount)+parseFloat(this.serviceTaxAmount);
    this.calculateBillingTotal();
  }

  calculateProductTotal() {
     debugger;

    let pBillingAmount=0;
     let pDiscountAmount=0;
     let pTaxableAmount=0;
     let pTaxAmount=0;

    
    for (let pt of this.productTable) {
      pBillingAmount = pBillingAmount + parseFloat(pt.MRP);
      pDiscountAmount = pDiscountAmount + parseFloat(pt.DiscountAmount);
      pTaxableAmount = pTaxableAmount + parseFloat(pt.Basic_Rate);
      pTaxAmount = pTaxAmount + parseFloat(pt.Amount);
    }

    this.productBillingAmount = pBillingAmount;
    this.productDiscountAmount = pDiscountAmount;
    this.productTaxableAmount = pTaxableAmount.toFixed(2);
    this.productTaxAmount = pTaxAmount.toFixed(2);
    this.productPaybalAmount = parseFloat(this.productTaxableAmount)+parseFloat(this.productTaxAmount);
    this.calculateBillingTotal();
  }

  calculateBillingTotal() {

    let billingAmount=parseFloat(this.serviceBillingAmount)+parseFloat(this.productBillingAmount);
    let discountgAmount=parseFloat(this.serviceDiscountAmount)+parseFloat(this.productDiscountAmount);
    let taxableAmount=parseFloat(this.serviceTaxableAmount)+parseFloat(this.productTaxableAmount);
    let taxAmount=parseFloat(this.serviceTaxAmount)+parseFloat(this.productTaxAmount);

    this.totalamount=billingAmount;
    this.discountAmount=discountgAmount;
    this.totalTaxbalAmount=taxableAmount;
    this.totalTaxAmount=taxAmount;
    let amount = 0;
    let dueAmount=parseFloat(this.dueAmount);
    let advanceAmount=parseFloat(this.advanceAmount);
    amount = billingAmount+dueAmount - (discountgAmount+advanceAmount);
    this.netPayableAmount = amount;
  }

  calculateNetBilling()
  {
    let taxableAmount=parseFloat(this.serviceTaxableAmount)+parseFloat(this.productTaxableAmount);
    let taxAmount=parseFloat(this.serviceTaxAmount)+parseFloat(this.productTaxAmount);
    let amount = 0;
    let extraDiscount=parseFloat(this.additionalDiscountAmount);
    let dueAmount=parseFloat(this.dueAmount);
    let advanceAmount=parseFloat(this.advanceAmount);
    let dummyAmount = taxableAmount+taxAmount;
    amount = dummyAmount-extraDiscount;
    amount = amount - parseInt(this.advanceAmount)
    amount = amount + parseInt(this.dueAmount)

    this.netPayableAmount = amount;
  }
}
